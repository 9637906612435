import { Container, Typography } from "@mui/material";
import { ReadMore } from "../ReadMore";

type NewChapterComponentProps = {
  title: string;
  text: string;
  image?: any;
  max: number;
};

const containerStyle: React.CSSProperties = {
  margin: "10px 0",
  maxWidth: "760px",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#f2f2f2",
  padding: "20px",
};

const NewChapterComponent: React.FC<NewChapterComponentProps> = ({
  title,
  text,
  image,
  max,
}) => {
  return (
    <Container maxWidth="md" style={containerStyle}>
      <Typography
        variant="h2"
        component="h2"
        color={"primary.main"}
        style={{
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
      <Typography variant="h5" component="h2" style={{ fontFamily: "Berlin" }}>
        <ReadMore maxCharacterCount={max} imageSrc={image}>
          {text}
        </ReadMore>
      </Typography>
    </Container>
  );
};

export default NewChapterComponent;
