export const focusGroupTabs = [
  {
    id: 1,
    value: "focus_group_1",
    title: "Fokusgruppe 1",
    summary: "Kinder",
    img: "personas/Kinder.svg",
    img2: "personas/01_personas.svg",
    description:
      "In dieser Fokusgruppe werden alle Kinder betrachtet. Darunter fassen wir alle Agenten, die mit einem Alter von maximal 15 Jahren attribuiert sind. \n \n Hinweis: Fokusgruppe 1 (Kinder) und Fokusgruppe 2 (Auszubildende und Studierende ohne Auto) sind ausschließlich Nutzende des öffentlichen Nahverkehrs und Fahrrads. Da ab 2030 alle Busse der BVG plangemäß elektrisch betrieben werden sollen, ist die Emissionen in diesen Szenarien gleich 0. ",
  },
  {
    id: 2,
    value: "focus_group_2",
    title: "Fokusgruppe 2",
    summary: "Auszubildende und Studierende ohne Auto",
    img: "personas/Studis.svg",
    img2: "personas/02_personas.svg",
    description:
      "In dieser Fokusgruppe werden Auszubildende und Studierende betrachtet. Darunter fassen wir im Modell alle Agenten, die zwischen 15 und 25 Jahren alt sind. Diese Agenten befinden sich in einer Ausbildung (Berufsschule bzw. Universität) und verfügen über kein Auto. \n \n Hinweis: Fokusgruppe 1 (Kinder) und Fokusgruppe 2 (Auszubildende und Studierende ohne Auto) sind ausschließlich Nutzende des öffentlichen Nahverkehrs und Fahrrads. Da ab 2030 alle Busse der BVG plangemäß elektrisch betrieben werden sollen, ist die Emissionen in diesen Szenarien gleich 0.",
  },
  {
    id: 3,
    value: "focus_group_3",
    title: "Fokusgruppe 3",
    summary: "Berufsanfänger*innen / young professionals",
    img: "personas/Young Professionals.svg",
    img2: "personas/03_personas.svg",
    description:
      "In dieser Fokusgruppe werden Berufsanfänger bzw. young professionals betrachtet. Darunter fassen wir im Modell alle Agenten, die zwischen 25 und 45 Jahren alt sind.",
  },
  {
    id: 4,
    value: "focus_group_4",
    title: "Fokusgruppe 4",
    summary: "Berufserfahrene / senior professionals",
    img: "personas/Senior Professionals.svg",
    img2: "personas/04_personas.svg",
    description:
      "In dieser Fokusgruppe werden alle Berufserfahrene bzw. senior professionals betrachtet. Darunter fassen wir im Modell alle Agenten, die zwischen 45 und 65 Jahren alt sind.",
  },
  {
    id: 5,
    value: "focus_group_5",
    title: "Fokusgruppe 5",
    summary: "Rentner*innen mit Auto",
    img: "personas/Renter mit Auto.svg",
    img2: "personas/05_personas.svg",
    description:
      "In dieser Fokusgruppe werden alle Rentner*innen mit Auto betrachtet. Diese Gruppe besteht aus allen Agenten, die älter als 65 Jahre alt sind und über ein Auto verfügen.",
  },
  {
    id: 6,
    value: "focus_group_6",
    title: "Fokusgruppe 6",
    summary: "Arbeitslose mit geringer Kaufkraft",
    img: "personas/Arbeitslos.svg",
    img2: "personas/06_personas.svg",
    description:
      "In dieser Fokusgruppe werden Arbeitslose mit einer geringen Kaufkraft betrachtet. Darunter fassen wir im Modell alle Agenten, die zwischen 15 und 65 Jahren alt sind und die sich weder in Ausbildung noch in einem Arbeitsverhältnis befinden. Die Kaufkraft dieser Agenten liegt zudem unter dem Berliner Durchschnitt (92,4). ",
  },
  {
    id: 7,
    value: "focus_group_7",
    title: "Fokusgruppe 7",
    summary: "Gutverdienende Singles und kinderlose Paare",
    img: "personas/Gut verdienende Singles.svg",
    img2: "personas/07_personas.svg",
    description:
      "In dieser Fokusgruppe werden gutverdienende Singles und Paare betrachtet. Darunter fassen wir im Modell alle Agenten, die zwischen 25 und 65 Jahren alt sind und mit maximal einer weiteren Person im Haushalt leben. Die Kaufkraft dieser Agenten liegt zudem über der durchschnittlichen Kaufkraft in Deutschland (100).",
  },
  {
    id: 8,
    value: "focus_group_8",
    title: "Fokusgruppe 8",
    summary: "Berufspendler*innen innerhalb Berlins",
    img: "personas/Pendler.svg",
    img2: "personas/08_personas.svg",
    description:
      "In dieser Fokusgruppe werden Berufspendler innerhalb Berlins betrachtet. Darunter fassen wir im Modell alle berufstätigen Agenten, die zwischen 15 und 65 Jahren alt sind und zur Arbeit in den Bereich innerhalb des S-Bahn-Rings pendeln. Der Wohnort dieser Agenten befindet sich außerhalb dieses Gebietes. Zusammengefasst werden hier alle Agenten, die entweder von ihrer Arbeitsstelle in der Innenstadt nach Hause (außerhalb des S-Bahn-Rings) oder von dort zu ihrer Arbeitsstelle fahren.",
  },
  {
    id: 9,
    value: "focus_group_9",
    title: "Fokusgruppe 9",
    summary: "Familien mit mind. 1 Kind",
    img: "personas/Familie.svg",
    img2: "personas/09_personas.svg",
    description:
      "In dieser Fokusgruppe werden alle Elternteile zwischen 45 und 65 Jahren mit mind. einem Kind betrachtet. Im Modell sind dies alle Agenten, die mit mind. zwei weiteren Personen in einem Haushalt leben.",
  },
  {
    id: 10,
    value: "focus_group_10",
    title: "Fokusgruppe 10",
    summary: "Berliner*innen mit unterdurchschnittlicher Kaufkraft",
    img: "personas/gerine Kaufkraft.svg",
    img2: "personas/10_personas.svg",
    description:
      "In dieser Fokusgruppe werden alle Berliner*innen betrachtet, deren Kaufkraft unter dem Berliner Durchschnitt (92,4) liegt.",
  },
  {
    id: 11,
    value: "focus_group_11",
    title: "Fokusgruppe 11",
    summary: "Berliner*innen mit überdurchschnittlicher Kaufkraft",
    img: "personas/Hohe Kaufkraft.svg",
    img2: "personas/11_personas.svg",
    description:
      "In dieser Fokusgruppe werden alle Berliner*innen betrachtet, deren Kaufkraft über dem Berliner Durchschnitt (92,4) liegt.",
  },
];
