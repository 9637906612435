export const newChapterContent = [
  {
    title: "Sechs Szenarien für Mobilität und Emissionen",
    text: `Auf diesem Dashboard zeigen wir dir die simulierten Auswirkungen unterschiedlicher Verkehrsmaßnahmen und ihren Beitrag zur Verbesserung der Luft in Berlin. Gemeinsam mit der Berliner Senatsverwaltung für Mobilität, Verkehr, Klimaschutz und Umwelt haben wir dazu verschiedene Szenarien entwickelt.   \n \n  Die simulierten Szenarien lassen sich in drei Kategorien gliedern: Zunächst werden die Mobilität und die dadurch entstehenden Emissionen an einem durchschnittlichen Werktag im Jahr 2023 dargestellt. Die zweite Simulation zeigt die Mobilität und verkehrsbedingten Emissionen im Berliner Stadtgebiet im Jahr 2030 unter der Prämisse, dass die auf diesen Zeithorizont ausgerichteten Pläne der Verwaltung auch tatsächlich umgesetzt werden. Die dritte Kategorie setzt das Erreichen dieser Ziele voraus, geht jedoch noch weiter: In den sog. Planspielen werden Maßnahmen simuliert, über deren Einführung die Berliner Verwaltung nachdenkt. Ob bzw. wann diese Maßnahmen in Berlin eingeführt werden, sowie ihre konkrete Ausgestaltung sind jedoch noch offen. Es liegen bislang keine konkreten Beschlüsse vor.
 \n Die Kalibrierung und Validierung des Gegenwarts-Szenarios erfolgt auf Grundlage verschiedener Daten. Die Simulationen aller zukünftigen Szenarien im Jahr 2030 können nicht kalibriert werden. Sie basieren auf Annahmen zur Entwicklung der Stadt sowie der Verkehrsmittelwahl der Berliner*innen unter den veränderten Parametern.`,
  },
  {
    title: "Fokusgruppen",
    text: `Grundsätzlich profitieren alle Berliner*innen von besserer Luft. Nicht alle Berliner*innen sind von den Auswirkungen der simulierten verkehrlichen Maßnahmen zur Verbesserung der Luftqualität aber in gleicher Weise betroffen. Gleichzeitig haben verschiedene Bevölkerungsgruppen aber auch einen unterschiedlichen Anteil an den ausgestoßenen Luftschadstoffen. Wir haben simuliert, wie sich das Mobilitätsverhalten verschiedener Bevölkerungsgruppen und der Ausstoß von Schadstoffen in verschiedenen Szenarien verändert.
    
    Die Fokusgruppen können über die sozio-demografischen Eigenschaften der Agenten im Modell gebildet werden. Jeder Agent ist bspw. mit Daten über sein Alter, seine Fahrzeugverfügbarkeit, die Haushaltsgröße, seinen Kaufkraftindex (wie viel Geld 

      Personen für ihre Konsumausgaben zur Verfügung steht) attribuiert sowie ob er berufstätig ist oder sich in Ausbildung (Berufsschule, Universität) befindet.

      Die Berliner Bevölkerung wurde zunächst in insg. fünf verschiedene Alterssegmente unterteilt. Darüber hinaus wurden sechs weitere Fokusgruppen mit einem spezifischeren Zuschnitt gebildet. `,
  },
  {
    title: "Vergleich Fokusgruppen",
    text: "Die Simulation hat gezeigt, dass sich die Luft in Berlin in Berlin durch die verkehrlichen Maßnahmen verbessern würde. Nicht alle Berliner*innen sind von den Auswirkungen der simulierten verkehrlichen Maßnahmen zur Verbesserung der Luftqualität in gleicher Weise betroffen. Welche Maßnahmen haben für welche Bevölkerungsgruppen welchen Effekt? Wir haben die Auswirkungen der Szenarien auf das Mobilitätsverhalten der verschiedenen Fokusgruppen und den daraus entstehenden Emissionen simuliert. Zum Vergleich haben wir sie hier nebeneinander gestellt.",
  },
  {
    title: "Methodik",
    text: `Die im Dashboard dargestellten Daten beruhen auf zwei Berechnungsschritten. Im ersten Schritt erfolgte eine agentenbasierte Verkehrsmodellierung (ABM) der sechs Szenarien. Darauf aufbauend wurden die verkehrsbedingten Emissionen für diese Szenarien nach dem Handbuch für Emissionsfaktoren im Straßenverkehr (HBEFA) errechnet. Die Visualisierung der Daten im Dashboard wurde mit der JavaScript-Bibliothek „REACT JS“ entwickelt. Dies ist eine Open-Source-Programmbibliothek zur Erstellung von webbasierten und nativen Benutzeroberflächen. \n \n Für die Visualisierung der Daten in interaktiven Karten und Diagrammen wurde “REACT JS” mit zusätzlichen quelloffenen Komponenten erweitert. Für die visuelle Darstellung der Berechnungen im Dashboard werden die entsprechend den Szenarien errechneten Daten aggregiert und kategorisch nach Verkehrsmodi und Emissionen unterschieden. Die resultierenden Werte stehen für durchschnittliche Tageswerte, die auf den Karten räumlich in Rasterzellen mit einer Größe von 50m x 50m zusammengefasst werden. \n \n Im Folgenden werden die einzelnen Komponenten zusammenfassend erläutert. Über die Reiter können zu jeder Komponente vertiefende Informationen ausgeklappt werden.`,
  },
];
