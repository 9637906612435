import { Box, Container, Grid, Typography } from "@mui/material";
import { tabs } from "../tabs";
import { descriptionStyle } from "./ScenarioDescriptionStyle";
import { scenarioContent } from "./ScenarioContent";

export const ScenarioDescription = ({ tab }: any) => {
  const index = tabs.indexOf(tab);
  return (
    <Box style={descriptionStyle.containerStyle}>
      <Typography variant="h5" component="h2" style={{ fontFamily: "Berlin" }}>
        {scenarioContent[index]?.description}
      </Typography>
      {scenarioContent[index]?.points.map((point, index) => {
        return (
          <Box style={{ display: "flex", marginTop: "10px" }} key={index}>
            <Typography variant="h5" component="h1" fontWeight={"bold"}>
              {point.title} <br />
              {point.description.map((descriptionPoint: string, index) => {
                return (
                  <Box
                    style={{ display: "flex", marginTop: "10px" }}
                    key={index}
                  >
                    <Typography variant="h5" component="h1">
                      {descriptionPoint}
                    </Typography>
                  </Box>
                );
              })}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
