import "./App.css";
import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import HeaderComponent from "./headerComponent/headerComponent";
import ScenarioTiles from "./scenarioComponent/scenarioTilesComponent/scenarioTilesComponent";
import FocusGroups from "./focusGroupComponent/focusGroupComponent";
import MethodikComponent from "./methodikComponent/methodikComponent";
import FooterComponent from "./footerComponent/footerComponent";
import FocusMapComponent from "./focusGroupComponent/focusGroupMap/focusMapComponent";
import FocusGroupComparisonComponent from "./focusGroupComparisonPerScenario/focusGroupComparisonComponent";
import FloatingMenu from "./helperComponents/floatingMenu/FloatingMenu";
import MainMapComponent from "./mainMapComponent/mainMapComponent";
const containerStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  alignSelf: "center",
  justifyContent: "center",
};
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container
        style={{ ...containerStyles, paddingTop: "24px" }}
        maxWidth="xl"
      >
        <FloatingMenu></FloatingMenu>
        <Container
          maxWidth="xl"
          style={{
            backgroundColor: "#f4f4f4",
            paddingTop: "20px",
            paddingBottom: "40px",
          }}
        >
          <HeaderComponent></HeaderComponent>
          <ScenarioTiles></ScenarioTiles>
        </Container>
        <MainMapComponent></MainMapComponent>
        <Container
          maxWidth="xl"
          style={{
            backgroundColor: "#f4f4f4",
            padding: "20px 0 40px 0",
            marginTop: "60px",
          }}
        >
          <FocusGroups></FocusGroups>
        </Container>
        <FocusMapComponent></FocusMapComponent>
        <Container
          maxWidth="xl"
          style={{
            backgroundColor: "#f4f4f4",
            padding: "20px",
            marginTop: "60px",
          }}
        >
          <FocusGroupComparisonComponent></FocusGroupComparisonComponent>
        </Container>
        <MethodikComponent></MethodikComponent>
        <FooterComponent></FooterComponent>
      </Container>
    </ThemeProvider>
  );
}

export default App;
