import { Box, Grid, Typography } from "@mui/material";
import ChartComponent from "../helperComponents/chartComponent/chartComponent";
import {
  index_emissions_scenarios,
  index_modal_split_scenarios,
  index_trip_length_scenarios,
} from "../focusGroupComponent/focusGroupMap/diagramDataSets";
import { scenarioContent } from "../scenarioComponent/scenarioDescription/ScenarioContent";
export const FocusGroupComparisonPerScenario = ({ scenario }: any) => {
  console.log(scenario);
  const options = {
    plugins: {
      title: {
        display: true,
        text: "",
        padding: {
          top: 10,
          bottom: 20,
        },
      },
      legend: {
        position: "center",
        labels: {
          boxWidth: 15, // Optional: Adjust the width of the legend color box
          padding: 10, // Optional: Adjust the padding between the legend items
        },
        title: {
          position: "center",
        },
      },
    },
  };
  return (
    <Box>
      <Typography
        variant="h2"
        component="h2"
        color={"primary.main"}
        style={{
          fontFamily: "Berlin",
          textAlign: "center",
          margin: "20px",
        }}
      >
        Szenario {scenario}: {scenarioContent[scenario - 1]?.title}
      </Typography>
      <Grid
        container
        item
        spacing={5}
        style={{
          alignItems: "stretch",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sm={6} md={4} style={{ display: "flex" }}>
          {/**index_emissions_scenarios */}
          <ChartComponent
            height="400px"
            data={index_emissions_scenarios[scenario - 1]}
            title={
              "Durchschn. CO2 (in g), NOx (in cg) and PM (in 100 µg) Emissionen dieser Fokusgruppe"
            }
            chartType={"bar"}
            options={options}
          ></ChartComponent>
        </Grid>

        <Grid item xs={12} sm={6} md={4} style={{ display: "flex" }}>
          {/**index_trip_length_scenarios */}
          <ChartComponent
            height="400px"
            title="Durchschnittliche Reisedistanz je Fokusgruppe (in km)"
            data={index_trip_length_scenarios[scenario - 1]}
            chartType={"bar"}
            options={options}
          ></ChartComponent>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ display: "flex" }}>
          {/**index_modal_split_scenarios */}
          <ChartComponent
            height="400px"
            data={index_modal_split_scenarios[scenario - 1]}
            title="Modal Split je Fokusgruppe (in %)"
            chartType={"bar"}
            options={{
              ...options,
              scales: {
                x: {
                  stacked: true, // Stack bars on top of each other on the x-axis
                },
                y: {
                  stacked: true, // Stack bars on top of each other on the y-axis
                },
              },
            }}
          ></ChartComponent>
        </Grid>
      </Grid>
    </Box>
  );
};
