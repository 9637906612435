import { Grid } from "@mui/material";
import ChartComponent from "../../helperComponents/chartComponent/chartComponent";
import {
  index_emissions_personas,
  index_modal_split_personas,
  index_trip_length_personas,
} from "./diagramDataSets";
export const FocusGroupDiagrams = ({ focusGroup }: any) => {
  const options = {
    plugins: {
      title: {
        display: true,
        text: "",
        padding: {
          top: 10,
          bottom: 20,
        },
      },
      legend: {
        position: "center",
        labels: {
          boxWidth: 15, // Optional: Adjust the width of the legend color box
          padding: 20, // Optional: Adjust the padding between the legend items
        },
        title: {
          position: "center",
        },
      },
    },
  };
  return (
    <Grid
      container
      item
      spacing={1}
      style={{
        alignItems: "stretch",
        display: "flex",
        justifyContent: "center",
        padding: "10px 0",
      }}
    >
      <Grid item xs={12} sm={6} md={4} style={{ display: "flex" }}>
        {/**index_emissions_personas */}
        <ChartComponent
          height="400px"
          title={
            "Durchschn. CO2 (in g), NOx (in cg) and PM (in 100 µg) Emissionen dieser Fokusgruppe / Tag"
          }
          data={index_emissions_personas[focusGroup - 1]}
          chartType={"bar"}
          options={options}
        ></ChartComponent>
      </Grid>
      <Grid item xs={12} sm={6} md={4} style={{ display: "flex" }}>
        {/**index_trip_length_personas */}
        <ChartComponent
          height="400px"
          title={
            "Durchschnittliche Reisedistanz dieser Fokusgruppe (in km) / Tag"
          }
          data={index_trip_length_personas[focusGroup - 1]}
          chartType={"bar"}
          options={options}
        ></ChartComponent>
      </Grid>
      <Grid item xs={12} sm={6} md={4} style={{ display: "flex" }}>
        {/**index_modal_split_personas */}
        <ChartComponent
          height="400px"
          title={"Modal Split dieser Fokusgruppe (in %) / Tag"}
          data={index_modal_split_personas[focusGroup - 1]}
          chartType={"bar"}
          options={{
            ...options,
            scales: {
              x: {
                stacked: true, // Stack bars on top of each other on the x-axis
              },
              y: {
                stacked: true, // Stack bars on top of each other on the y-axis
              },
            },
          }}
        ></ChartComponent>
      </Grid>
    </Grid>
  );
};
