export const index_emissions_personas = [
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [28.69, 0.0, 0.0, 0.0, 0.0, 0.0],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [10.3, 0.0, 0.0, 0.0, 0.0, 0.0],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [6.56, 0.05, 0.04, 0.05, 0.05, 0.04],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [49.59, 0.0, 0.0, 0.0, 0.0, 0.0],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [17.81, 0.0, 0.0, 0.0, 0.0, 0.0],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [11.34, 0.08, 0.07, 0.09, 0.08, 0.07],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [433.09, 265.99, 242.77, 165.86, 192.93, 117.36],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [75.18, 21.31, 19.34, 13.44, 15.39, 9.45],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [67.84, 20.84, 18.97, 13.11, 15.11, 9.27],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [486.0, 301.54, 273.71, 193.31, 218.45, 135.96],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [83.03, 24.15, 21.79, 15.64, 17.42, 10.93],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [75.57, 23.61, 21.38, 15.26, 17.1, 10.72],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [728.87, 503.83, 458.78, 274.26, 409.91, 196.88],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [116.64, 40.47, 36.59, 22.65, 32.67, 16.1],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [110.39, 39.42, 35.78, 21.8, 31.98, 15.59],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [476.76, 309.86, 285.6, 161.34, 247.75, 115.92],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [80.83, 24.71, 22.64, 13.2, 19.64, 9.41],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [73.81, 24.2, 22.25, 12.81, 19.32, 9.2],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [543.61, 350.2, 317.77, 209.8, 264.53, 148.21],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [91.48, 28.11, 25.34, 17.19, 21.13, 12.06],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [84.03, 27.43, 24.82, 16.65, 20.71, 11.75],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [444.02, 167.27, 142.05, 2.3, 71.49, 1.75],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [86.01, 12.87, 10.86, 0.18, 5.48, 0.14],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [72.41, 12.99, 11.01, 0.39, 5.65, 0.32],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [878.97, 597.33, 555.55, 339.68, 482.45, 246.39],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [140.13, 47.55, 43.99, 27.63, 38.25, 19.92],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [132.52, 46.55, 43.19, 26.82, 37.56, 19.42],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [357.08, 222.35, 205.44, 97.26, 178.01, 68.3],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [62.63, 17.68, 16.24, 7.94, 14.01, 5.52],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [56.09, 17.37, 16.0, 7.75, 13.86, 5.44],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [459.23, 298.88, 272.44, 176.73, 233.49, 126.8],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [77.77, 23.96, 21.7, 14.47, 18.64, 10.31],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [71.16, 23.4, 21.27, 14.02, 18.27, 10.05],
      },
    ],
  },
];

export const index_emissions_scenarios = [
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [
          28.69, 49.59, 433.09, 486.0, 728.87, 476.76, 543.61, 444.02, 878.97,
          357.08, 459.23,
        ],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [
          10.3, 17.81, 75.18, 83.03, 116.64, 80.83, 91.48, 86.01, 140.13, 62.63,
          77.77,
        ],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [
          6.56, 11.34, 67.84, 75.57, 110.39, 73.81, 84.03, 72.41, 132.52, 56.09,
          71.16,
        ],
      },
    ],
  },
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [
          0.0, 0.0, 265.99, 301.54, 503.83, 309.86, 350.2, 167.27, 597.33,
          222.35, 298.88,
        ],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [
          0.0, 0.0, 21.31, 24.15, 40.47, 24.71, 28.11, 12.87, 47.55, 17.68,
          23.96,
        ],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [
          0.05, 0.08, 20.84, 23.61, 39.42, 24.2, 27.43, 12.99, 46.55, 17.37,
          23.4,
        ],
      },
    ],
  },
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [
          0.0, 0.0, 242.77, 273.71, 458.78, 285.6, 317.77, 142.05, 555.55,
          205.44, 272.44,
        ],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [
          0.0, 0.0, 19.34, 21.79, 36.59, 22.64, 25.34, 10.86, 43.99, 16.24,
          21.7,
        ],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [
          0.04, 0.07, 18.97, 21.38, 35.78, 22.25, 24.82, 11.01, 43.19, 16.0,
          21.27,
        ],
      },
    ],
  },
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [
          0.0, 0.0, 165.86, 193.31, 274.26, 161.34, 209.8, 2.3, 339.68, 97.26,
          176.73,
        ],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [
          0.0, 0.0, 13.44, 15.64, 22.65, 13.2, 17.19, 0.18, 27.63, 7.94, 14.47,
        ],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [
          0.05, 0.09, 13.11, 15.26, 21.8, 12.81, 16.65, 0.39, 26.82, 7.75,
          14.02,
        ],
      },
    ],
  },
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [
          0.0, 0.0, 192.93, 218.45, 409.91, 247.75, 264.53, 71.49, 482.45,
          178.01, 233.49,
        ],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [
          0.0, 0.0, 15.39, 17.42, 32.67, 19.64, 21.13, 5.48, 38.25, 14.01,
          18.64,
        ],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [
          0.05, 0.08, 15.11, 17.1, 31.98, 19.32, 20.71, 5.65, 37.56, 13.86,
          18.27,
        ],
      },
    ],
  },
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "g CO2",
        backgroundColor: "#800080",
        data: [
          0.0, 0.0, 117.36, 135.96, 196.88, 115.92, 148.21, 1.75, 246.39, 68.3,
          126.8,
        ],
      },
      {
        label: "cg NOx",
        backgroundColor: "#51414F",
        data: [
          0.0, 0.0, 9.45, 10.93, 16.1, 9.41, 12.06, 0.14, 19.92, 5.52, 10.31,
        ],
      },
      {
        label: "100 µg PM",
        backgroundColor: "#AA336A",
        data: [
          0.04, 0.07, 9.27, 10.72, 15.59, 9.2, 11.75, 0.32, 19.42, 5.44, 10.05,
        ],
      },
    ],
  },
];

export const index_modal_split_personas = [
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 0, 0, 0, 0],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 0, 0, 0, 0],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [26, 29, 22, 30, 29, 23],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [74, 71, 78, 70, 71, 77],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 0, 0, 0, 0],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 0, 0, 0, 0],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [59, 62, 52, 63, 62, 53],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [41, 38, 48, 37, 38, 47],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [28, 22, 19, 15, 15, 10],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [1, 3, 3, 4, 2, 2],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [44, 48, 42, 53, 54, 51],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [27, 27, 36, 28, 29, 37],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [31, 25, 21, 17, 17, 11],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [1, 4, 3, 4, 3, 3],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [41, 44, 40, 51, 51, 48],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [27, 27, 36, 28, 29, 38],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [49, 41, 35, 27, 32, 18],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [1, 6, 5, 7, 4, 5],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [24, 26, 24, 35, 33, 35],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [26, 27, 36, 31, 31, 42],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [30, 25, 21, 15, 19, 10],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [1, 4, 3, 4, 3, 3],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [41, 44, 40, 51, 49, 48],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [28, 27, 36, 30, 29, 39],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [36, 29, 25, 20, 22, 14],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [1, 4, 4, 5, 3, 3],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [38, 43, 37, 49, 48, 46],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [25, 24, 34, 26, 27, 37],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [17, 10, 7, 0, 4, 0],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [1, 1, 1, 2, 1, 1],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [64, 70, 61, 78, 75, 68],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [18, 19, 31, 20, 20, 31],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [55, 44, 39, 30, 36, 21],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [2, 7, 6, 9, 6, 6],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [24, 28, 26, 37, 35, 39],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [19, 21, 29, 24, 23, 34],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [23, 18, 15, 10, 13, 7],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [1, 3, 2, 3, 2, 2],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [42, 46, 41, 52, 50, 47],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [34, 33, 42, 35, 35, 44],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [31, 25, 22, 17, 19, 11],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [1, 4, 3, 4, 3, 3],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [35, 39, 34, 45, 44, 42],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [33, 32, 41, 34, 34, 44],
      },
    ],
  },
];

export const index_modal_split_scenarios = [
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 28, 31, 49, 30, 36, 17, 55, 22, 31],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 1, 1, 1, 1, 1, 1, 2, 1, 1],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [26, 59, 44, 41, 24, 41, 38, 64, 24, 43, 35],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [74, 41, 27, 27, 26, 28, 25, 18, 19, 34, 33],
      },
    ],
  },
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 22, 25, 41, 25, 29, 9, 45, 17, 25],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 3, 4, 6, 4, 4, 1, 7, 3, 4],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [29, 62, 48, 44, 26, 44, 43, 71, 27, 47, 39],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [71, 38, 27, 27, 27, 27, 24, 19, 21, 33, 32],
      },
    ],
  },
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 15, 17, 27, 15, 20, 0, 30, 9, 17],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 4, 4, 7, 4, 5, 2, 9, 3, 4],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [30, 63, 53, 51, 35, 51, 49, 78, 37, 53, 45],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [70, 37, 28, 28, 31, 30, 26, 20, 24, 35, 34],
      },
    ],
  },
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 19, 21, 35, 21, 25, 7, 39, 15, 21],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 3, 3, 5, 3, 4, 1, 6, 2, 3],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [22, 52, 42, 40, 24, 40, 37, 61, 26, 41, 35],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [78, 48, 36, 36, 36, 36, 34, 31, 29, 42, 41],
      },
    ],
  },
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 15, 17, 32, 19, 22, 4, 36, 13, 19],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 2, 3, 4, 3, 3, 1, 6, 2, 3],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [29, 62, 54, 51, 33, 49, 48, 75, 35, 50, 44],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [71, 38, 29, 29, 31, 29, 27, 20, 23, 35, 34],
      },
    ],
  },
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 10, 11, 18, 10, 14, 0, 21, 6, 12],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 2, 3, 5, 3, 3, 1, 6, 2, 3],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [23, 53, 51, 48, 35, 48, 46, 68, 39, 48, 41],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [77, 47, 37, 38, 42, 39, 37, 31, 34, 44, 44],
      },
    ],
  },
];

export const index_trip_length_personas = [
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 0, 0, 0, 0],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 0, 0, 0, 0],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [12.0, 11.4, 12.4, 11.4, 11.4, 12.3],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [3.8, 3.8, 4.1, 3.8, 3.8, 4.1],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 0, 0, 0, 0],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 0, 0, 0, 0],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [9.2, 8.9, 9.0, 8.9, 8.9, 8.9],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [4.5, 4.5, 5.3, 4.5, 4.5, 5.3],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [12.4, 12.8, 13.5, 11.7, 13.4, 12.3],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [12.2, 12.5, 13.2, 11.7, 13.0, 12.5],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [9.5, 9.6, 9.7, 10.0, 10.0, 10.3],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [4.1, 4.2, 5.0, 4.3, 4.3, 5.2],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [12.6, 12.9, 13.6, 11.8, 13.4, 12.5],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [12.0, 12.8, 13.6, 12.2, 13.3, 13.2],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [10.0, 10.1, 10.2, 10.5, 10.6, 10.8],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [4.1, 4.2, 5.2, 4.4, 4.4, 5.3],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [12.3, 12.6, 13.4, 10.1, 13.4, 10.9],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [11.8, 12.5, 13.3, 12.0, 13.6, 13.6],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [8.3, 8.7, 8.8, 9.6, 9.5, 9.9],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [3.7, 3.8, 4.6, 4.0, 4.0, 4.8],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [13.1, 13.4, 14.1, 10.9, 14.1, 11.5],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [12.9, 13.3, 14.1, 12.7, 14.0, 14.1],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [9.2, 9.2, 9.3, 9.7, 9.6, 9.9],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [4.0, 4.0, 4.9, 4.2, 4.1, 5.0],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [12.5, 12.6, 13.4, 10.7, 13.1, 11.3],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [12.2, 12.4, 13.2, 12.1, 13.0, 13.1],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [10.0, 10.0, 10.2, 10.6, 10.5, 10.9],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [4.1, 4.2, 5.2, 4.4, 4.4, 5.4],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [17.8, 19.4, 20.9, 13.7, 20.5, 13.1],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [16.9, 18.5, 20.6, 17.6, 19.1, 18.4],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [14.0, 14.2, 14.7, 14.7, 14.6, 15.2],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [6.7, 6.9, 8.5, 7.4, 7.1, 8.6],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [13.4, 13.6, 14.3, 11.3, 14.1, 11.9],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [12.7, 13.6, 14.3, 13.1, 14.1, 14.4],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [9.3, 9.8, 9.9, 10.9, 10.9, 11.4],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [3.9, 4.0, 5.0, 4.4, 4.3, 5.3],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [13.1, 13.7, 14.5, 11.0, 15.1, 12.0],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [12.7, 13.7, 14.5, 12.8, 15.2, 14.7],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [8.9, 8.8, 8.9, 9.1, 9.1, 9.3],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [3.8, 3.8, 4.5, 3.9, 3.9, 4.6],
      },
    ],
  },
  {
    labels: [
      "Gegenwart (Berlin heute)",
      "Berlin im Jahr 2030",
      "Planspiel Kiezblocks",
      "Planspiel Elektromobilität",
      "Planspiel Parkraumbewirtschaftung",
      "Kombination aller Planspiele",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [12.6, 12.8, 13.5, 10.8, 13.2, 11.4],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [12.3, 12.7, 13.4, 12.2, 13.2, 13.3],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [10.0, 9.9, 10.1, 10.4, 10.4, 10.8],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [4.0, 4.1, 4.9, 4.3, 4.2, 5.0],
      },
    ],
  },
];

export const index_trip_length_scenarios = [
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 12.4, 12.6, 12.3, 13.1, 12.5, 17.8, 13.4, 13.1, 12.6],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 12.2, 12.0, 11.8, 12.9, 12.2, 16.9, 12.7, 12.7, 12.3],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [12.0, 9.2, 9.5, 10.0, 8.3, 9.2, 10.0, 14.0, 9.3, 8.9, 10.0],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [3.8, 4.5, 4.1, 4.1, 3.7, 4.0, 4.1, 6.7, 3.9, 3.8, 4.0],
      },
    ],
  },
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 12.8, 12.9, 12.6, 13.4, 12.6, 19.4, 13.6, 13.7, 12.8],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 12.5, 12.8, 12.5, 13.3, 12.4, 18.5, 13.6, 13.7, 12.7],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [11.4, 8.9, 9.6, 10.1, 8.7, 9.2, 10.0, 14.2, 9.8, 8.8, 9.9],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [3.8, 4.5, 4.2, 4.2, 3.8, 4.0, 4.2, 6.9, 4.0, 3.8, 4.1],
      },
    ],
  },
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 13.5, 13.6, 13.4, 14.1, 13.4, 20.9, 14.3, 14.5, 13.5],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 13.2, 13.6, 13.3, 14.1, 13.2, 20.6, 14.3, 14.5, 13.4],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [12.4, 9.0, 9.7, 10.2, 8.8, 9.3, 10.2, 14.7, 9.9, 8.9, 10.1],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [4.1, 5.3, 5.0, 5.2, 4.6, 4.9, 5.2, 8.5, 5.0, 4.5, 4.9],
      },
    ],
  },
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 11.7, 11.8, 10.1, 10.9, 10.7, 13.7, 11.3, 11.0, 10.8],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 11.7, 12.2, 12.0, 12.7, 12.1, 17.6, 13.1, 12.8, 12.2],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [11.4, 8.9, 10.0, 10.5, 9.6, 9.7, 10.6, 14.7, 10.9, 9.1, 10.4],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [3.8, 4.5, 4.3, 4.4, 4.0, 4.2, 4.4, 7.4, 4.4, 3.9, 4.3],
      },
    ],
  },
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 13.4, 13.4, 13.4, 14.1, 13.1, 20.5, 14.1, 15.1, 13.2],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 13.0, 13.3, 13.6, 14.0, 13.0, 19.1, 14.1, 15.2, 13.2],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [11.4, 8.9, 10.0, 10.6, 9.5, 9.6, 10.5, 14.6, 10.9, 9.1, 10.4],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [3.8, 4.5, 4.3, 4.4, 4.0, 4.1, 4.4, 7.1, 4.3, 3.9, 4.2],
      },
    ],
  },
  {
    labels: [
      "Fokusgruppe 1",
      "Fokusgruppe 2",
      "Fokusgruppe 3",
      "Fokusgruppe 4",
      "Fokusgruppe 5",
      "Fokusgruppe 6",
      "Fokusgruppe 7",
      "Fokusgruppe 8",
      "Fokusgruppe 9",
      "Fokusgruppe 10",
      "Fokusgruppe 11",
    ],
    datasets: [
      {
        label: "MIV_Verbrenner",
        backgroundColor: "red",
        data: [0, 0, 12.3, 12.5, 10.9, 11.5, 11.3, 13.1, 11.9, 12.0, 11.4],
      },
      {
        label: "MIV_EV",
        backgroundColor: "blue",
        data: [0, 0, 12.5, 13.2, 13.6, 14.1, 13.1, 18.4, 14.4, 14.7, 13.3],
      },
      {
        label: "ÖV",
        backgroundColor: "orange",
        data: [12.3, 8.9, 10.3, 10.8, 9.9, 9.9, 10.9, 15.2, 11.4, 9.3, 10.8],
      },
      {
        label: "Fahrrad",
        backgroundColor: "green",
        data: [4.1, 5.3, 5.2, 5.3, 4.8, 5.0, 5.4, 8.6, 5.3, 4.6, 5.0],
      },
    ],
  },
];
