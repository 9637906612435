import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Berlin"].join(","),
    htmlFontSize: 16,
    h1: {
      fontSize: "42px",
      lineHeight: "1.5",
    },
    h2: {
      fontSize: "28px",
      lineHeight: "1.5",
    },
    h3: {
      fontSize: "24px",
      lineHeight: "1.5",
    },
    h4: {
      fontSize: "18px",
      lineHeight: "1",
    },
    h5: {
      fontSize: "16px",
      lineHeight: "1.2",
    },
  },

  palette: {
    primary: {
      main: "#212529",
      dark: "#f4f4f4",
      light: "#f1f1f1",
    },
    secondary: {
      main: "#0a58ca",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "h2",
          subtitle2: "h2",
          body1: "span",
          body2: "span",
        },
      },
    },
  },
});

export default theme;
