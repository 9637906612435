import { Box } from "@mui/material";
import ChartComponent from "../helperComponents/chartComponent/chartComponent";
import { modalSplitOptions, emissionOptions, avgKmOptions } from "./options";

export const MainChartsComponent = ({ feature }: any) => {
  return (
    <Box>
      <Box
        style={{
          border: "1px solid #B3B3B3",
          margin: "0 0 10px 10px",
          flex: 1,
        }}
      >
        <ChartComponent
          height={"200px"}
          data={{
            labels: ["Verbrenner", "E-Auto", "ÖV", "Fahrrad"],

            datasets: [
              {
                label: "",
                data: [
                  feature.properties?.car,
                  feature.properties?.ev_car,
                  feature.properties?.pt,
                  feature.properties?.bike,
                ],
                backgroundColor: ["red", "blue", "orange", "green"],
              },
            ],
          }}
          chartType={"pie"}
          title={"Modal Split " + feature.properties?.PLR_NAME + " / Tag"}
          options={modalSplitOptions}
        ></ChartComponent>
      </Box>
      <Box
        style={{
          border: " 1px solid #B3B3B3",
          marginRight: "10px",
          flex: 1,
          margin: "0 0 10px 10px",
        }}
      >
        <ChartComponent
          height={"200px"}
          data={{
            labels: ["CO2 - t", "NOx - kg", "PM - dag"],
            datasets: [
              {
                label: "",
                data: [
                  feature.properties?.co2_sum,
                  feature.properties?.nox_sum,
                  feature.properties?.pm_sum,
                ],
                backgroundColor: ["#800080", "#51414F", "#AA336A"],
              },
            ],
          }}
          chartType={"bar"}
          options={emissionOptions}
          title={
            "Emissionen aller Verkehrsträger " +
            feature.properties?.PLR_NAME +
            " / Tag"
          }
        ></ChartComponent>
      </Box>
      <Box
        style={{
          border: " 1px solid #B3B3B3",
          flex: 1,
          margin: "0 0 10px 10px",
        }}
      >
        <ChartComponent
          height={"200px"}
          data={{
            labels: ["Verbrenner", "E-Auto", "ÖV", "Fahrrad"],
            datasets: [
              {
                label: "",
                data: [
                  feature.properties?.avgkm_car,
                  feature.properties?.avgkm_ev_car,
                  feature.properties?.avgkm_pt,
                  feature.properties?.avgkm_bike,
                ],
                backgroundColor: ["red", "blue", "orange", "green"],
              },
            ],
          }}
          title={
            "Durchschnittliche Reisedistanz " +
            feature.properties?.PLR_NAME +
            " / Tag"
          }
          chartType={"bar"}
          options={avgKmOptions}
        ></ChartComponent>
      </Box>
    </Box>
  );
};
