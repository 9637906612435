import { Tabs, Tab, Box } from "@mui/material";

export const NavigationTabs = ({
  tabs,
  tab,
  handleTabChange,
  tabStyle,
}: any) => {
  return (
    <Box boxShadow={3}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        TabIndicatorProps={{ sx: { display: "none" } }}
        style={{
          padding: "10px",
          width: "100%",
          zIndex: 100,
          display: "flex",
          backgroundColor: "##a0a5a7",
          justifyContent: "space-between",
        }}
        variant={"scrollable"}
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
        sx={{
          "& .MuiTab-root": {
            textTransform: "none",
            fontSize: "14px",
            color: "#0a58ca",
            "&.Mui-selected": {
              backgroundColor: "#007ba3",
              color: "#ffffff",
              fontWeight: "bold",
            },
          },
        }}
      >
        {tabs?.map((tab: any, index: number) => {
          return (
            <Tab
              value={tab.value}
              style={{ flexGrow: 1, ...tabStyle }}
              label={tab.label ? tab.label : tab.title}
              key={index}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};
