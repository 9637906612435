import React from "react";
import { Box, Grid, Paper, Typography, makeStyles } from "@mui/material";

const classes: any = {
  legendContainer: {
    padding: "5px",
    margin: "5px",
    display: "flex",
    backgroundColor: "white",
  },
};

const Legend = ({ legendValues }: any) => {
  return (
    <Box
      className={"leaflet-bottom leaflet-left"}
      style={classes.legendContainer}
    >
      {legendValues.map((legend: any, index: any) => {
        return (
          <Box
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: "10px",
              marginRight: "10px",
              borderRight:
                legendValues.length !== index + 1 ? "1px solid lightgrey" : "",
            }}
          >
            <Typography variant="h5" fontSize={12}>
              {legend.title}
            </Typography>
            <Box
              style={{
                backgroundColor: legend.color,
                width: "20px",
                height: "10px",
                marginLeft: "20px",
              }}
            ></Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Legend;
