import { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Box, Grid, Typography, debounce } from "@mui/material";
import { fetchFocusGroupGeoJson } from "../../services/layerFetcher";
import { resetHighlight } from "../../mainMapComponent/util";
import { ScenarioControl } from "../../scenarioComponent/ScenarioControl";
import { focusGroupTabs } from "../focusGroupList";
import { modeMap } from "./modeMap";
import GradientLegend, { getColorFromAttribute } from "./colorLegend";

const FocusGroupMap = ({ focusGroup }: any) => {
  const mapRef = useRef(null);
  const latitude = 51.505;
  const longitude = -0.09;
  const [mode, setMode] = useState("all");
  const [max, setMax] = useState(0);
  const handleChange = (event: any, nextView: string) => {
    setMode(nextView);
  };
  useEffect(() => {
    initiateMap();
  }, [mode, focusGroup]);

  const initiateMap = () => {
    const url_to_geotiff_file = `/focus_groups/pi_${focusGroup}_lorzonen.geojson`;

    Promise.all([fetchFocusGroupGeoJson(url_to_geotiff_file)])
      .then(([geojsonLayer]) => {
        const map = mapRef.current as L.Map | null;
        let mapGeojsonLayer = handleLayer(geojsonLayer);
        if (map) {
          map.eachLayer((layer) => {
            map.removeLayer(layer);
          });
          mapGeojsonLayer.addTo(map);
          map.setView([52.507159, 13.451734], 10);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleLayer = (geojsonLayer: any) => {
    let maxValue = 0;

    geojsonLayer.features.map((feature: any) => {
      maxValue =
        maxValue < feature.properties[mode]
          ? feature.properties[mode]
          : maxValue;
    });
    setMax(maxValue);
    let mapGeojsonLayer = L.geoJSON(geojsonLayer, {
      style: function (feature) {
        const attribute = feature?.properties[mode];

        const { color } = getColorFromAttribute(attribute, mode, maxValue);

        return {
          weight: 0.5,
          color: "lightgray",
          fillOpacity: 0.8,
          fillColor: color,
        };
      },
      onEachFeature: (feature, layer) => onEachFeature(feature, layer),
    });
    return mapGeojsonLayer;
  };

  const onEachFeature = (feature: any, layer: any) => {
    var popupContent =
      `Anzahl ausgehende Fahrten ${modeMap[mode]}: ` +
      feature?.properties[mode];
    layer.bindPopup(popupContent);
    layer.on({
      mouseover: function (e: any) {
        this.openPopup();
        highlightFeature(e); // Optional: Highlight the feature on mouseover
      },
      mouseout: function (e: any) {
        this.closePopup();
        resetHighlight(e); // Optional: Reset the highlight on mouseout
      },
    });
  };
  const highlightFeature = (e: any) => {
    var layer = e.target;
    layer.setStyle({
      weight: 2,
      color: "red",
    });
  };
  return (
    <Box>
      <Typography
        variant="h2"
        component="h2"
        color={"primary.main"}
        style={{
          margin: "0 0 10px 0",
        }}
      >
        {focusGroupTabs[focusGroup - 1].title}
      </Typography>
      <Grid container>
        <Grid item xs={12} md={9}>
          <MapContainer
            center={[latitude, longitude]}
            zoom={11}
            ref={mapRef}
            scrollWheelZoom={false} // Disable scroll wheel zoom
            doubleClickZoom={false} // Disable double click zoom
            zoomControl={false} // Disable zoom control
            touchZoom={false} // Disable touch zoom
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "450px",
              backgroundColor: "#e5e5e5",
            }}
          >
            <ScenarioControl
              position="topright"
              type={mode}
              verkehrTitle={
                "Gesamtzahl der Fahrten pro Modus im Gegenwarts-Szenario"
              }
              handleTypeChange={handleChange}
            />
            <GradientLegend attributeCategory={mode} max={max} />{" "}
            {/* Render the ColorLegend component */}
          </MapContainer>
        </Grid>
        <Grid item xs={12} md={3} style={{ paddingLeft: "10px" }}>
          <Box>
            <Typography variant="h4" component="h2">
              {focusGroupTabs[focusGroup - 1].summary}
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              style={{ flex: 1, paddingTop: "10px", whiteSpace: "pre-line" }}
            >
              {focusGroupTabs[focusGroup - 1].description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FocusGroupMap;
