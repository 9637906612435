export const tabs = [
  { id: 1, value: "base_min_2023", label: "Szenario 1: Gegenwart" },
  { id: 2, value: "bau_2030", label: "Szenario 2: Plan 2030" },
  {
    id: 3,
    value: "vis_umw_2030",
    label: "Szenario 3: Planspiel Elektromobilität",
  },
  {
    id: 4,
    value: "vis_ver_2030",
    label: "Szenario 4: Planspiel Parkraumbewirtschaftung",
  },
  { id: 5, value: "vis_rad_2030", label: "Szenario 5: Planspiel Kiezblocks" },
  {
    id: 6,
    value: "kvis_2030",
    label: "Szenario 6: Kombination aller Planspiele",
  },
];
