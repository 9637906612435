import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import parse from "html-react-parser";
type CollapsableCardProps = {
  question: string;
  image?: string;
  answer: string;
};

const CollapsableCard: React.FC<CollapsableCardProps> = ({
  question,
  image,
  answer,
}) => {
  return (
    <Container
      sx={{ width: 1 }}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Accordion
        sx={{
          mb: 1,
          backgroundColor: "#E5E5E5",
          boxShadow: "none",
          "& .MuiSvgIcon-root": {
            color: "#0a58ca",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            variant="h5"
            component="h2"
            style={{
              fontWeight: "bold",
              fontFamily: "Berlin",
              padding: "0 16px",
              wordBreak: "break-word",
            }}
          >
            {question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box style={{ display: "flex" }}>
            <Box style={{ flex: 1, padding: "10px" }}>
              {image ? (
                <img
                  src={image}
                  alt="HIIG Logo"
                  style={{
                    width: "287px",
                    padding: "5px 10px 10px 0",
                    objectFit: "contain",
                    float: "left",
                    height: "auto",
                  }}
                />
              ) : null}

              <Typography
                style={{
                  fontFamily: "Berlin",
                  whiteSpace: "pre-line",
                }}
              >
                {parse(answer)}
              </Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default CollapsableCard;
