import { Box } from "@mui/material";
import StickyTabs from "../helperComponents/stickyTabs/StickyTabs";
import { tabs } from "../scenarioComponent/tabs";
import { FocusGroupComparisonPerScenario } from "./focusGroupComparisonPerScenario";
import { useState } from "react";
import NewChapterComponent from "../helperComponents/newChapterComponent/newChapterComponent";
import { newChapterContent } from "../helperComponents/newChapterComponent/newChapterContent";

const FocusGroupComparisonComponent = () => {
  const [scenario, setScenario] = useState("base_min_2023");
  const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
    setScenario(newValue);
  };
  return (
    <Box
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      id="sectionComparison"
    >
      <StickyTabs
        tabs={tabs}
        tab={scenario}
        handleTabChange={handleTabChange}
        id={"sectionComparison"}
      ></StickyTabs>
      <NewChapterComponent
        text={newChapterContent[2].text}
        title={newChapterContent[2].title}
        max={550}
      ></NewChapterComponent>
      <FocusGroupComparisonPerScenario
        scenario={tabs.find((tab: any) => tab.value === scenario)?.id}
      ></FocusGroupComparisonPerScenario>
    </Box>
  );
};
export default FocusGroupComparisonComponent;
