import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Collapse,
  Link,
  useMediaQuery,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
type ReadMoreProps = {
  children: string;

  imageSrc: string;
  maxCharacterCount?: number;
};

export const ReadMore: React.FC<ReadMoreProps> = ({
  children,
  imageSrc,
  maxCharacterCount = 100,
}) => {
  const text = children;
  const [isExpanded, setIsExpanded] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  if (text.length <= maxCharacterCount) {
    return <Typography>{text}</Typography>;
  }

  return (
    <Box>
      <Box
        sx={{ display: "flex", flexWrap: isSmallScreen ? "wrap" : "nowrap" }}
      >
        {imageSrc ? (
          <img
            src={imageSrc}
            alt="HIIG Logo"
            style={{
              width: "287px",
              height: "auto",
              padding: "8px 10px 8px 0",
              objectFit: "contain",
              alignSelf: "baseline",
            }}
          />
        ) : null}
        <Typography style={{ whiteSpace: "pre-line" }}>
          {isExpanded ? text : `${text.substring(0, maxCharacterCount)}... `}
        </Typography>
      </Box>

      <Link
        variant="h5"
        onClick={handleExpandClick}
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "right",
          color: "#0a58ca",
        }}
      >
        {isExpanded ? (
          <KeyboardArrowUpIcon> </KeyboardArrowUpIcon>
        ) : (
          <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
        )}
      </Link>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Typography
          style={{
            fontFamily: "Berlin",
            padding: "16px",
          }}
          variant="body1"
        ></Typography>
      </Collapse>
    </Box>
  );
};
