import * as parseGeoraster from "georaster";
export const fetchGeoRasterLayer = (url: string) => {
  return fetch(url)
    .then((response) => response.arrayBuffer())
    .then((arrayBuffer) => parseGeoraster(arrayBuffer))
    .then((georaster) => georaster);
};

export const fetchGeoJson = (url: string) => {
  return fetch(url).then((response) => response.json());
};

export const fetchFocusGroupGeoJson = (url: string) => {
  return fetch(url).then((response) => response.json());
};
