import { Box, Container, Typography } from "@mui/material";
import NewChapterComponent from "../helperComponents/newChapterComponent/newChapterComponent";
import { newChapterContent } from "../helperComponents/newChapterComponent/newChapterContent";
import CollapsableCard from "./collapsableCard.tsx/collapsableCard";
import { methodikContent } from "./methodikContent";
import parse from "html-react-parser";
const MethodikComponent = () => {
  return (
    <Container
      maxWidth="md"
      id="section4"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "60px",
        padding: "16px 0",
      }}
    >
      <NewChapterComponent
        text={newChapterContent[3].text}
        image={"./logos/dashboard.png"}
        title={newChapterContent[3].title}
        max={500}
      ></NewChapterComponent>
      <Container>
        {methodikContent.map((content: any, index: number) => {
          const width: any = content.width ? content.width : "287px";
          return (
            <Container key={index}>
              <Typography
                variant="h5"
                component="h2"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: "2rem",
                  marginTop: "2rem",
                }}
              >
                {content.title}
              </Typography>
              <Typography
                variant="h5"
                component="h2"
                style={{
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                {" "}
                <Box style={{ display: "flex" }}>
                  <Box style={{ flex: 1, padding: "10px" }}>
                    {content.image ? (
                      <img
                        src={content.image}
                        alt="HIIG Logo"
                        style={{
                          width: width,
                          height: "auto",
                          padding: "5px 10px 10px 0",
                          objectFit: "contain",
                          float: "left",
                          alignSelf: "baseline",
                        }}
                      />
                    ) : null}
                    <Box>{parse(content.description)}</Box>
                  </Box>
                </Box>
              </Typography>
              {content.dropdown?.map((item: any, qIndex: number) => (
                <Container key={qIndex}>
                  <CollapsableCard
                    question={item.question}
                    image={item.image}
                    answer={item.answer}
                  ></CollapsableCard>
                </Container>
              ))}
            </Container>
          );
        })}
      </Container>
      <Container
        style={{
          display: "flex",
          padding: "42px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          style={{
            marginBottom: "10px",
            fontWeight: "bold",
            fontFamily: "Berlin",
            padding: "0 16px",
            wordBreak: "break-word",
          }}
        >
          Credits
        </Typography>
        <Typography variant="h5">
          Die Berechnungen der Szenarien sowie die Aufbereitung der Ergebnisse
          in einer interaktiven Bedienoberfläche wurden vom{" "}
          <a href="https://www.hiig.de/" style={{ textTransform: "none" }}>
            Alexander von Humboldt Institut für Internet und Gesellschaft | HIIG{" "}
          </a>
          im Rahmen ihres Projekts „Data & Smart City Governance am Beispiel von
          Luftgütemanagement“ in Auftrag gegeben. Die inhaltliche Koordination
          und wissenschaftliche Forschung des Projekts findet in Zusammenarbeit
          mit dem{" "}
          <a
            href="https://kompetenz-wasser.de/de"
            style={{ textTransform: "none" }}
          >
            Kompetenzzentrum Wasser Berlin
          </a>{" "}
          statt. Das Projekt wird gefördert vom Regierenden Bürgermeister von
          Berlin – Senatskanzlei – aus Mitteln des Bundesministeriums für
          Wohnen, Stadtentwicklung und Bauwesen sowie der Kreditanstalt für
          Wiederaufbau.
          <br /> Die{" "}
          <a href="https://www.arup.com/" style={{ textTransform: "none" }}>
            Arup Deutschland GmbH
          </a>{" "}
          hat als Auftragnehmerin alle Rohdaten aus der agentenbasierten
          Modellierung der{" "}
          <a href="https://senozon.com/" style={{ textTransform: "none" }}>
            Senozon Deutschland GmbH
          </a>{" "}
          weiterverarbeitet, die Emissionsberechnungen durchgeführt und alle
          daraus resultierenden Karten, Graphiken und Daten erstellt und in der
          Bedienoberfläche zusammengeführt.
        </Typography>
      </Container>
    </Container>
  );
};

export default MethodikComponent;
