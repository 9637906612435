export const scenarioContent = [
  {
    title: `Gegenwart`,
    description: `In dieser Simulation werden der Status Quo der Berliner Mobilität und die daraus entstehenden Emissionen im Jahr 2023 abgebildet. 
                  Die Agenten bewegen sich auf dem aktuell vorhandenen Straßen- und ÖPNV-Netz.
                   Aktuell existierende Parkraumbewirtschaftungszonen mit den entsprechenden Preisen sind berücksichtigt`,
    summary: "Berlin heute",
    img: "/scenarios/01_scenario.svg",
    points: [
      {
        title: `Bevölkerung`,
        description: [
          `Die gegenwärtige Demografie Berlins ist im Modell abgebildet und die Agenten sind repräsentativ mit sozio-ökonomischen Parametern der Berliner
         Bevölkerung belegt.`,
        ],
      },
      {
        title: `ÖPNV und MIV sowie Aktive Mobilität (Rad)`,
        description: [`Es wird das aktuelle Mobilitätsverhalten dargestellt.`],
      },
    ],
  },
  {
    title: `Plan 2030`,
    description: `In diesem Szenario wird simuliert, wie die Mobilität und die daraus entstehenden Emissionen in Berlin sein werden,
     wenn sich die Stadtbevölkerung bis dahin wie derzeit angenommen entwickelt und die aktuell beschlossenen Pläne umgesetzt werden.`,
    summary: "Berlin im Jahr 2030",
    img: "/scenarios/02_scenario.svg",
    points: [
      {
        title: `Bevölkerung`,
        description: [
          `Aktuell wird für 2030 von einem Bevölkerungswachstum von +3% ausgegangen. 
        Für die Simulation ergibt sich daraus eine um 3% erhöhte Anzahl der Agenten, die sich in diesem Szenario durch die Stadt bewegen.`,
        ],
      },
      {
        title: `ÖPNV`,
        description: [
          `Entsprechend der derzeitigen Pläne wird angenommen, dass die gesamte Flotte der Berliner Verkehrsbetriebe (BVG) bis 2030 elektrifiziert ist.`,
        ],
      },
      {
        title: `MIV`,
        description: [
          `Wir nehmen einen moderaten Anstieg von 2,5% auf 12,5% bei E-Autos bis 2030 an (siehe Link). Diese Zunahme ist von der Berliner SenMVKU validiert.`,
          `Als Grundlage zur Berechnung der Veränderung im Verkehrsverhalten wurde für dieses Szenario der für 2030 projizierte MIV-Anteil (22,4%) genommen.`,
          `Der 16. Abschnitt der Berliner Stadtautobahn A100 (bis zum Treptower Park) sowie die Elsenbrücke werden fertiggestellt sein und sind in das Straßennetz integriert.`,
          `Entsprechend der aktuellen Planungen der Stadt wird der Parkraum im Bereich innerhalb des S-Bahn-Rings vollständig bewirtschaftet. Die geplanten Kostensteigerungen für einen Bewohnerparkausweise (pauschal 20€ / Monat) sowie das Kurzzeitparken bzw. Fremdparken (pauschal 4€ / Stunde) sind in der Simulation berücksichtigt.`,
        ],
      },
      {
        title: `Aktive Mobilität (Rad)`,
        description: [
          `Änderungen im Modal Split Anteil des ÖPNV orientieren sich an der Kalibrierung des Modal Split Wertes des MIV.`,
        ],
      },
    ],
  },
  {
    title: `Planspiel Elektromobilität`,
    description: `Dieses Szenario baut auf dem Plan 2030 auf. Zusätzlich zu den erwarteten Entwicklungen wird hier ein klimaneutraler Verkehr innerhalb des S-Bahn-Rings modelliert: Innerhalb des S-Bahn-Rings dürfen hier nur noch Autos mit elektrischem Antrieb fahren. 
       Für dieses Planspiel gibt es bisher kein Umsetzungsbeispiel in Deutschland. Die in einigen Städten diskutierte Idee hat allerdings große Ähnlichkeit mit den bereits existierenden Umweltzonen.
        In diesem Fall ist Autos ohne die in der jeweiligen Zone erforderliche Plakette (z.B. ältere Diesel-Fahrzeuge) die Einfahrt in bestimmte Gebiete nicht gestattet.`,
    summary: "Berlin im Jahr 2030 mit klimaneutralem Verkehr im S-Bahn-Ring",
    img: "/scenarios/03_scenario.svg",
    points: [
      {
        title: `Bevölkerung, ÖPNV`,
        description: [`Die Annahmen entsprechen dem Szenario Plan 2030.`],
      },
      {
        title: `MIV`,
        description: [
          `Innerhalb des S-Bahn-Rings dürfen nur noch Autos mit Elektroantrieb fahren. Agenten, deren Autos einen Verbrennerantrieb haben, müssen somit auf alternative Verkehrsmittel (z.B. ÖPNV oder Rad) ausweichen, um in dieses Gebiet einfahren zu können. Außerhalb des S-Bahn-Rings dürfen weiterhin alle Antriebsarten uneingeschränkt fahren.`,
        ],
      },
      {
        title: `Aktive Mobilität (Rad)`,
        description: [`Die Annahmen entsprechen dem Szenario Plan 2030.`],
      },
    ],
  },
  {
    title: `Planspiel Parkraumbewirtschaftung`,
    description: `Dieses Szenario baut auf dem Szenario Plan 2030 auf, erweitert allerdings die Anzahl an kostenpflichtigen Parkgebieten bei gleichzeitig erhöhten Parkkosten.`,
    summary:
      "Berlin im Jahr 2030 mit zusätzlichen Parkzonen und deutlich höheren Preisen",
    img: "/scenarios/04_scenario.svg",
    points: [
      {
        title: `Bevölkerung, ÖPNV`,
        description: [`Die Annahmen entsprechen dem Szenario Plan 2030`],
      },
      {
        title: `MIV`,
        description: [
          `Die Einführung zusätzlicher Parkraumbewirtschaftung außerhalb des Ringes erfolgt in zusammenhängenden Zonen mit einer Einwohnerdichte von mehr 200 Einwohner*innen pro Hektar.`,
          `Neben dem gesamten Gebiet innerhalb des S-Bahn-Rings sind in diesem Szenario auch besonders dicht besiedelte Gebiete außerhalb, wie beispielsweise die Spandauer Altstadt, kostenpflichtig. Die Parkkosten übersteigen außerdem die ÖPNV Ticketpreise und betragen jetzt 50€ pro Monat für Anwohner*innen (zum Vergleich: das Deutschlandticket kostet aktuell 49€ pro Monat) und 10€ pro Stunde für ortsfremde Parker*innen. Insgesamt ergibt dieser Preis eine Steigerung von 250% gegenüber den für 2030 absehbaren Kosten.`,
        ],
      },
      {
        title: `Aktive Mobilität (Rad)`,
        description: [`Die Annahmen entsprechen dem Szenario Plan 2030.`],
      },
    ],
  },
  {
    title: `Planspiel Kiezblocks`,
    description: `Dieses Szenario baut auf dem Szenario Plan 2030 auf, erweitert die Planungen jedoch um eine weit gefasste Verkehrsberuhigung innerhalb des S-Bahn-Rings. In dieser Simulation gelten innerhalb dieses Gebiets Einschränkungen für den motorisierten Individualverkehr in Nebenstraßen, wodurch der Verkehr in den Kiezen jenseits der Hauptstraßen deutlich reduziert wird. Diese Maßnahme kommt den sog. Kiezblocks am nächsten, wobei jedoch nicht die Einfahrt in Nebenstraßen grundsätzlich unmöglich wird, sondern die Durchfahrt. Der Aufbau fest installierter Hindernisse, wie bspw. Poller, sind hier nicht gemeint. Anlieger und ÖPNV dürfen auch weiterhin in Nebenstraßen einfahren.`,
    summary:
      "Berlin im Jahr 2030 mit Verkehrsberuhigung in Nebenstraßen im S-Bahn-Ring",
    img: "/scenarios/05_scenario.svg",
    points: [
      {
        title: `Bevölkerung, ÖPNV`,
        description: [`Die Annahmen entsprechen dem Szenario Plan 2030`],
      },
      {
        title: `MIV`,
        description: [
          `Die Modellierung von Maßnahmen, um Autos den Durchgangsverkehr auf Nebenstraßen zu verwehren („Kiezblöcke“), ist technisch nicht möglich. Stattdessen wurde die Attraktivität zur Nutzung der Nebenstraßen durch Autos innerhalb des S-Bahn Ringes reduziert.`,
        ],
      },
    ],
  },
  {
    title: `Kombination aller Planspiele`,
    description: `Dieses Szenario baut auf dem Plan 2030 auf, erweitert die Planungen jedoch um alle drei Maßnahmen, die in den Planspielen einzeln simuliert sind.
     In diesem Szenario darf der Bereich innerhalb des S-Bahn-Rings demnach 1) nur noch mit Elektrofahrzeugen befahren werden, der Parkraum ist 2) sowohl auf den gesamten Bereich innerhalb des S-Bahn-Rings also auch in den entsprechenden Gebieten außerhalb mit hohen Gebühren bewirtschaftet und 3) sind die Nebenstraßen innerhalb des S-Bahn-Rings für den Durchgangsverkehr ohne Anliegen nicht zugänglich.`,
    summary: "Berlin im Jahr 2030 kombiniert mit allen drei Planspielen",
    img: "/scenarios/06_scenario.svg",
    points: [
      {
        title: `Bevölkerung`,
        description: [`Die Annahmen entsprechen dem Szenario Plan 2030.`],
      },
      {
        title: `ÖPNV, MIV und aktive Mobilität (Rad)`,
        description: [
          `Die kombinierten Annahmen aus den Szenarien 2–5 dienen als Grundlage.`,
        ],
      },
    ],
  },
];
