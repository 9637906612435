export const verkehrLegend = [
  {
    title: "Verbrenner",
    color: "red",
  },
  {
    title: "E-Auto",
    color: "blue",
  },
  {
    title: "ÖV",
    color: "orange",
  },
  {
    title: "Fahrrad",
    color: "green",
  },
];

export const emissionLegend = [
  {
    title: "Co2",
    color: "#800080",
  },
  {
    title: "NOx",
    color: "#51414F",
  },
  {
    title: "PM",
    color: "#AA336A",
  },
];
