import { Container, Grid } from "@mui/material";
import { focusGroupTabs } from "./focusGroupList";
import NewChapterComponent from "../helperComponents/newChapterComponent/newChapterComponent";
import { newChapterContent } from "../helperComponents/newChapterComponent/newChapterContent";
import { Item } from "../helperComponents/IconItem";

const FocusGroups = () => {
  return (
    <Container
      maxWidth="xl"
      id="section2"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        whiteSpace: "pre-line",
      }}
    >
      <NewChapterComponent
        text={newChapterContent[1].text}
        title={newChapterContent[1].title}
        max={510}
      ></NewChapterComponent>

      <Container maxWidth="lg" style={{ marginTop: "20px" }}>
        <Grid container spacing={4} justifyContent="center">
          {focusGroupTabs.map((focusGroup: any, index: number) => {
            return <Item key={index} {...focusGroup} width="120px" />;
          })}
        </Grid>
      </Container>
    </Container>
  );
};

export default FocusGroups;
