import { Grid, Box, Typography } from "@mui/material";
import { ReadMore } from "./ReadMore";

export const Item = ({ img, title, summary, width }: any) => (
  <Grid item xs={6} sm={4} md={2} style={{}}>
    <Box
      style={{
        padding: "10px 10px 0 10px",
        backgroundColor: "#007ba3",
        height: "100%",
        color: "#ffffff",
      }}
    >
      <img
        src={img}
        alt={title}
        style={{
          width: "100%",
          height: "auto",
          backgroundColor: "white",
        }}
      />
      <Typography
        variant="h4"
        fontWeight={"bold"}
        style={{ overflowWrap: "break-word", padding: "5px 0" }}
      >
        {title}
      </Typography>
      <Typography variant="h5" style={{ overflowWrap: "break-word" }}>
        {summary}
      </Typography>
    </Box>
  </Grid>
);
