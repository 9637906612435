import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { useState, useMemo } from "react";

const POSITION_CLASSES: any = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};

export const ScenarioControl = ({
  position,
  type,
  verkehrTitle,
  handleModeChange,
  handleTypeChange,
}: any) => {
  const [mode, setMode] = useState("verkehr");
  const handleMode = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    if (!nextView) {
      return;
    }
    setMode(nextView);
    handleModeChange(nextView);
  };

  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
  return (
    <Box className={positionClass} style={{ zIndex: 500, width: "200px" }}>
      <Box
        className="leaflet-control leaflet-bar"
        style={{ border: "none", display: "flex", flexDirection: "column" }}
      >
        {handleModeChange ? (
          <ToggleButtonGroup
            style={{
              backgroundColor: "white",
              border: "1px solid #1513BC",
              display: "flex",
              justifyContent: "space-between",
              borderRadius: 0,
              marginBottom: "5px",
            }}
            value={mode}
            exclusive
            onChange={handleMode}
            aria-label="Platform"
          >
            <ToggleButton
              value="verkehr"
              style={{
                textTransform: "none",
                flex: 1,
                borderRadius: 0,
                padding: 0,
              }}
            >
              Verkehr
            </ToggleButton>

            <ToggleButton
              value="emission"
              style={{
                textTransform: "none",
                flex: 1,
                padding: 0,
                borderRadius: 0,
              }}
            >
              Emissionen
            </ToggleButton>
          </ToggleButtonGroup>
        ) : null}
        {mode === "verkehr" ? (
          <FormControl
            style={{
              backgroundColor: "white",
              border: "none",
              fontSize: "12px",
              padding: "10px",
            }}
          >
            <FormLabel
              component="legend"
              style={{
                padding: "0 5px ",
                flexWrap: "wrap",
              }}
            >
              <Typography
                variant="h5"
                component="h5"
                style={{ fontSize: "12px", paddingBottom: "5px" }}
              >
                {verkehrTitle}
              </Typography>
            </FormLabel>
            <RadioGroup
              style={{ backgroundColor: "white", fontSize: "12px" }}
              aria-label="Modus Transport"
              name="modusTransport"
              value={type}
              onChange={handleTypeChange}
            >
              <FormControlLabel
                value="all"
                style={{ paddingLeft: "10px" }}
                control={<Radio sx={{ padding: "0 5px " }} />}
                label={<span style={{ fontSize: "12px" }}>Gesamt</span>}
              />
              <FormControlLabel
                value="car"
                style={{ paddingLeft: "10px" }}
                control={<Radio sx={{ padding: "0 5px " }} />}
                label={<span style={{ fontSize: "12px" }}>Verbrenner</span>}
              />
              <FormControlLabel
                value="ev"
                style={{ paddingLeft: "10px" }}
                control={<Radio sx={{ padding: "0 5px " }} />}
                label={<span style={{ fontSize: "12px" }}>E-Auto</span>}
              />
              <FormControlLabel
                value="bus"
                style={{ paddingLeft: "10px" }}
                control={<Radio sx={{ padding: "0 5px " }} />}
                label={
                  <span style={{ fontSize: "12px" }}>
                    Öffentlicher Nahverkehr
                  </span>
                }
              />
              <FormControlLabel
                value="bike"
                style={{ paddingLeft: "10px" }}
                control={<Radio sx={{ padding: "0 5px " }} />}
                label={<span style={{ fontSize: "12px" }}>Fahrrad</span>}
              />
            </RadioGroup>
          </FormControl>
        ) : (
          <FormControl
            style={{
              backgroundColor: "white",
              border: "none",
              fontSize: "12px",
              padding: "10px",
            }}
          >
            <FormLabel
              component="legend"
              style={{
                padding: "0 5px ",
                flexWrap: "wrap",
              }}
            >
              <Typography
                variant="h5"
                component="h5"
                style={{ fontSize: "12px", paddingBottom: "5px" }}
              >
                Menge der Emissionen je Emissionsart
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-label="Modus Transport"
              name="modusTransport"
              value={type}
              onChange={handleTypeChange}
            >
              <FormControlLabel
                value="all"
                style={{ paddingLeft: "10px" }}
                control={<Radio sx={{ padding: "0 5px " }} />}
                label={<span style={{ fontSize: "12px" }}>Gesamt</span>}
              />
              <FormControlLabel
                value="co2"
                style={{ paddingLeft: "10px" }}
                control={<Radio sx={{ padding: "0 5px " }} />}
                label={<span style={{ fontSize: "12px" }}>CO2</span>}
              />
              <FormControlLabel
                value="nox"
                style={{ paddingLeft: "10px" }}
                control={<Radio sx={{ padding: "0 5px " }} />}
                label={<span style={{ fontSize: "12px" }}>NOx</span>}
              />
              <FormControlLabel
                value="pm"
                style={{ paddingLeft: "10px" }}
                control={<Radio sx={{ padding: "0 5px " }} />}
                label={<span style={{ fontSize: "12px" }}>PM</span>}
              />
            </RadioGroup>
          </FormControl>
        )}
      </Box>
    </Box>
  );
};
