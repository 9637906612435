import React from "react";
import "./colorLegend.css";
import { Box } from "@mui/material";

export const getColorFromAttribute = (
  attributeValue: number,
  attributeCategory: string,
  maxValue: number
): { color: string; darkColor: string } => {
  const normalizedValue =
    Math.min(maxValue, Math.max(0, attributeValue)) / maxValue;

  const categoryColors: { [key: string]: { hue: number; darkColor: string } } =
    {
      all: { hue: 30, darkColor: "#73573A" }, // Update with actual values
      car: { hue: 0, darkColor: "#733A3A" },
      ev: { hue: 240, darkColor: "#6767E0" },
      bus: { hue: 60, darkColor: "#73733B" },
      bike: { hue: 120, darkColor: "#3B733A" },
      // Add other categories and their corresponding colors
    };

  const { hue, darkColor } = categoryColors[attributeCategory] || {
    hue: 30,
    darkColor: "#005000",
  };

  const saturation = "70%";
  let lightness: string;

  if (attributeValue === 0) {
    lightness = "100%"; // White color for attributeValue 0
  } else {
    lightness = 100 - normalizedValue * 80 + "%";
  }

  const color = `hsl(${hue}, ${saturation}, ${lightness})`;
  return { color, darkColor };
};

const GradientLegend = ({ attributeCategory, max }: any) => {
  const gradientStyle = (darkColor: string) => ({
    width: "20px",
    height: "100px",
    background: `linear-gradient(to top, #FFFFFF, ${darkColor})`,
  });

  const { darkColor: startDarkColor } = getColorFromAttribute(
    0,
    attributeCategory,
    max
  );

  return (
    <Box className="legend-container leaflet-bottom leaflet-left">
      <Box className="legend-item" style={{ display: "flex" }}>
        <Box style={gradientStyle(startDarkColor)}></Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ marginLeft: "5px" }}>{max}</Box>
          <Box style={{ marginLeft: "5px" }}>0</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GradientLegend;
