export const descriptionStyle: any = {
  containerStyle: {
    margin: "10px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "8px",
  },
  pointStyle: {
    fontFamily: "Berlin",
    whiteSpace: "pre-line",
    display: "list-item",
  },
};
