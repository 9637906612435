// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add your styles for the floating menu */
.floating-menu {
  position: fixed;
  bottom: 0;
  right: 32px;
  z-index: 10000;
  max-width: 150px;
  transform: translateY(-50%);
  border-radius: 8px;
  background-color: #007ba3;
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/helperComponents/floatingMenu/floatingMenu.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;EACE,eAAe;EACf,SAAS;EACT,WAAW;EACX,cAAc;EACd,gBAAgB;EAChB,2BAA2B;EAC3B,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;AACf","sourcesContent":["/* Add your styles for the floating menu */\r\n.floating-menu {\r\n  position: fixed;\r\n  bottom: 0;\r\n  right: 32px;\r\n  z-index: 10000;\r\n  max-width: 150px;\r\n  transform: translateY(-50%);\r\n  border-radius: 8px;\r\n  background-color: #007ba3;\r\n  padding: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
