import { Box, Container, Typography } from "@mui/material";

const HeaderComponent = () => {
  return (
    <Container
      maxWidth="lg"
      style={{
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h1"
        component="h2"
        color={"primary.main"}
        style={{
          fontFamily: "Berlin",
          textAlign: "center",
        }}
      >
        Bessere Luft durch Verkehrswende?
      </Typography>
      <Typography
        variant="h3"
        component="h2"
        color={"primary.main"}
        style={{
          fontFamily: "Berlin",
          textAlign: "center",
        }}
      >
        Dashboard zur Visualisierung der Auswirkungen von Verkehrsmaßnahmen auf
        die Luft in Berlin 
      </Typography>
    </Container>
  );
};

export default HeaderComponent;
