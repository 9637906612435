import { Container } from "@mui/material";
import FocusGroupMap from "./focusGroupMap";
import { focusGroupTabs } from "../focusGroupList";
import { useEffect, useState } from "react";
import { FocusGroupDiagrams } from "./focusGroupDiagrams";
import StickyTabs from "../../helperComponents/stickyTabs/StickyTabs";

const FocusMapComponent = () => {
  const [tab, setTab] = useState("focus_group_1");
  const [id, setId] = useState(1);

  const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
    setTab(newValue);
  };

  useEffect(() => {
    const tabObj = focusGroupTabs.find((obj) => obj.value === tab);
    const idVal = tabObj ? tabObj.id : 1;
    setId(idVal);
  }, [tab]);
  return (
    <Container maxWidth="lg" style={{ margin: "60px 0" }}>
      <StickyTabs
        tabs={focusGroupTabs}
        tab={tab}
        handleTabChange={handleTabChange}
        id={"section2"}
      ></StickyTabs>

      <FocusGroupMap focusGroup={id}></FocusGroupMap>
      <FocusGroupDiagrams focusGroup={id}></FocusGroupDiagrams>
    </Container>
  );
};

export default FocusMapComponent;
