import { Box } from "@mui/material";
import Chart, { ChartType } from "chart.js/auto";
import { useEffect, useRef } from "react";

type ChartComponentProps = {
  data: any;
  chartType: ChartType;
  options: any;
  height: string;
  title?: string;
};

const ChartComponent: React.FC<ChartComponentProps> = ({
  data,
  chartType,
  options,
  title,
  height,
}) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (options.plugins.title && title) {
      options.plugins.title.text = "";
      options.plugins.title.text = `${options.plugins?.title?.text} ${title}`;
    }
    if (chartRef.current) {
      const myChart = new Chart(chartRef.current, {
        type: chartType,
        data: data,
        options: options,
      });

      // Cleanup the chart when the component is unmounted
      return () => {
        myChart.destroy();
      };
    }
  }, [data, chartType, options]);

  return (
    <Box
      style={{
        height: height,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <canvas
        ref={chartRef}
        style={{ width: "100%", height: "100%", display: "flex" }}
      />
    </Box>
  );
};

export default ChartComponent;
