export const modalSplitOptions = {
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context: any) {
          const labelIndex = context.dataIndex;
          const dataset = context.dataset;
          const total = dataset.data.reduce(
            (acc: any, val: any) => acc + val,
            0
          );
          const value = dataset.data[labelIndex];
          const percentage = ((value / total) * 100).toFixed(2);
          return `${percentage}%`;
        },
      },
    },

    title: {
      display: true,
      text: "Modal Split ",
    },
    legend: {
      position: "left",
      labels: {
        boxWidth: 15, // Optional: Adjust the width of the legend color box
        padding: 10, // Optional: Adjust the padding between the legend items
      },
      title: {
        position: "left",
      },
    },
  },
};

export const emissionOptions = {
  scales: {
    y: {
      type: "linear",
      ticks: {
        stepSize: 0.05,
        beginAtZero: true,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Emissionen ",
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          let label = context.dataset.label || "";
          if (context.parsed.y !== null) {
            // Round the data value to two decimal places
            const roundedValue = Math.round(context.parsed.y);
            label += ": " + roundedValue; // Update tooltip label with rounded value
          }
          return label;
        },
      },
    },
  },
};

export const avgKmOptions = {
  scales: {
    y: {
      title: {
        display: true,
        text: "In Km",
        align: "center",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Durchschnittliche Reisedistanz ",
    },
  },
};
