import { useState, useEffect } from "react";
import "./stick.css";
import { NavigationTabs } from "../NavigationTabs";

const StickyTabs = ({ tabs, tab, handleTabChange, id }: any) => {
  const [isSticky, setSticky] = useState(false);
  const [range, setRange] = useState(0);

  useEffect(() => {
    const targetElement = document.getElementById(id);
    let lastSection = document.getElementById("section4");
    let limit = 100000;
    if (lastSection) {
      const { top } = lastSection.getBoundingClientRect();
      limit = top + window.scrollY - 300;
    }

    const calculateRange = () => {
      if (targetElement) {
        const { top } = targetElement.getBoundingClientRect();
        // Adjust the range as needed, here adding some offset (e.g., 100 pixels) for better usability
        setRange(top + window.scrollY - 300);
      }
    };

    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > range && offset < limit) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    calculateRange();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [range]);

  return (
    <div className={`sticky-tabs ${isSticky ? "sticky" : "hidden"}`}>
      <NavigationTabs
        tabs={tabs}
        tab={tab}
        handleTabChange={handleTabChange}
      ></NavigationTabs>
    </div>
  );
};

export default StickyTabs;
