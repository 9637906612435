// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky-tabs {
  position: relative;
  z-index: 1;
  background-color: #ffffff;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  right: 0;
}

.hidden {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/helperComponents/stickyTabs/stick.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,aAAa;AACf","sourcesContent":[".sticky-tabs {\r\n  position: relative;\r\n  z-index: 1;\r\n  background-color: #ffffff;\r\n}\r\n\r\n.sticky {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  z-index: 1000;\r\n  right: 0;\r\n}\r\n\r\n.hidden {\r\n  display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
