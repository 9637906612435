import { Box, Link, Typography } from "@mui/material";
import "./floatingMenu.css";

const FloatingMenu = () => {
  const handleMenuItemClick = (id: any) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = element.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({ top: offset, behavior: "smooth" });
    }
  };

  return (
    <Box className="floating-menu">
      <Typography>
        <a
          style={{ color: "#ffffff", textTransform: "none", cursor: "pointer" }}
          onClick={() => handleMenuItemClick("section4")}
        >
          Möchtest du wissen wie es funktioniert?{" "}
          Zu den Daten und Methoden.
        </a>
      </Typography>
    </Box>
  );
};

export default FloatingMenu;
