import L from "leaflet";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import chroma from "chroma-js";
import { colors, emissionColors } from "./modes";

export const onEachFeature = (
  feature: any,
  layer: any,
  setFeatureState: Function,
  map: any
) => {
  var popupContent = "LOR-Zone: " + feature.properties.PLR_NAME;
  layer.bindPopup(popupContent);

  layer.on({
    mouseover: function (e: any) {
      this.openPopup();
      highlightFeature(e); // Optional: Highlight the feature on mouseover
    },
    mouseout: function (e: any) {
      this.closePopup();
      resetHighlight(e); // Optional: Reset the highlight on mouseout
    },
    click: (e: any) => {
      map.fitBounds(e.target.getBounds());
      setFeatureState(e.target.feature);
    },
  });
};

export const highlightFeature: any = (e: any) => {
  var layer = e.target;

  layer.setStyle({
    weight: 2,
    color: "red",
  });
};

export const resetHighlight = (e: any) => {
  var layer = e.target;

  layer.setStyle({
    weight: 0.0,
    color: "",
  });
};

export const addTile = (map: any) => {
  L.tileLayer(
    "https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3RlbnplbG1hdXJpY2VoaWlnIiwiYSI6ImNscGdwcjlzazAwcWoya252bWF2cXVzb3oifQ.o4JCMqNzDz-AM_z5FE4OwQ",
    {
      attribution:
        '© <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> contributors',
    }
  ).addTo(map);
};

export const addGeoJsonLayer = (geojsonLayer: any, map: any) => {
  geojsonLayer.addTo(map);
};

export const addGeoRasterLayer = (
  georaster: any,
  map: any,
  selection: string,
  color?: string
) => {
  const chosenColor = color ? color : colorMap(selection);
  const minValue = georaster.mins[0];
  const maxValue = georaster.maxs[0];

  type PixelValuesToColorFn = (values: number[]) => string;
  const gradient: PixelValuesToColorFn = (value: number[]) =>
    chroma(chosenColor)
      .alpha(Math.pow(value[0] / maxValue, 0.5))
      .toString();

  const georasterLayer = new GeoRasterLayer({
    georaster: georaster,
    opacity: 1,
    pixelValuesToColorFn: gradient,
    resolution: 256,
  });

  georasterLayer.addTo(map);
};

export const setUpMap = (
  map: any,
  mode: any,
  geojsonLayer: any,
  georasters: any,
  selectedValue: string
) => {
  if (map) {
    // Remove existing layers

    map.eachLayer((layer: any) => {
      map.removeLayer(layer);
    });

    addTile(map);

    addGeoJsonLayer(geojsonLayer, map);
    if (selectedValue === "all") {
      georasters.forEach((raster: any, index: any) => {
        let colorArray = mode === "verkehr" ? colors : emissionColors;
        addGeoRasterLayer(raster, map, selectedValue, colorArray[index]);
      });
    } else {
      addGeoRasterLayer(georasters[0], map, selectedValue);
    }

    map.setView([52.507159, 13.451734], 11);
  }
};

export const colorMap = (selectedValue: any) => {
  let color;
  switch (selectedValue) {
    case "car":
      color = "red";
      break;
    case "co2":
      color = "#800080";
      break;
    case "ev":
      color = "blue";
      break;
    case "nox":
      color = "#51414F";
      break;
    case "bus":
      color = "orange";
      break;
    case "pm":
      color = "#AA336A";
      break;
    case "bike":
      color = "green";
      break;
    default:
      color = "grey";
  }
  return color;
};
