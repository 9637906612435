import { Box, Container, Divider, Grid } from "@mui/material";
import { scenarioContent } from "../scenarioDescription/ScenarioContent";
import { Item } from "../../helperComponents/IconItem";
import NewChapterComponent from "../../helperComponents/newChapterComponent/newChapterComponent";
import { newChapterContent } from "../../helperComponents/newChapterComponent/newChapterContent";

const ScenarioTiles = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <NewChapterComponent
        text={newChapterContent[0].text}
        title={newChapterContent[0].title}
        max={300}
      ></NewChapterComponent>
      <Container
        maxWidth="lg"
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "40px",
          alignItems: "flex-start",
        }}
      >
        <Grid container spacing={2}>
          {scenarioContent.map((scenario: any, index: number) => {
            return <Item key={index} {...scenario} width="160px" />;
          })}
        </Grid>
      </Container>
      <Divider></Divider>
    </Box>
  );
};

export default ScenarioTiles;
