export const methodikContent = [
  {
    title: "Agentenbasierte Verkehrsmodellierung (ABM)",
    description: `Die Simulation der Mobilität im Berliner Stadtgebiet erfolgt auf Basis des agentenbasierten Verkehrsmodells für Berlin der Senozon Deutschland GmbH. Darin wird die Berliner Bevölkerung durch eine Vielzahl von so genannten Agenten repräsentiert. Diese bewegen sich nach bestimmten Regeln auf verschiedenen Routen und mit unterschiedlichen Zielen durch die Stadt. Unsere Simulation enthält ca. 1.000.000 Agenten, die jeweils mit eigenen soziodemografischen Eigenschaften ausgestattet sind - so als würden sich 1.000.000 Berliner Einwohner*innen mit ihren typischen Eigenschaften durch die Stadt bewegen.
`,
    dropdown: [
      {
        question: "Was sind agentenbasierte Verkehrsmodelle?",
        answer: `Eine ABM kann man bildhaft anhand eines Vogelschwarms erklären. Stell dir einen Vogelschwarm vor: Die Vögel führen einen kunstvollen Tanz auf und zeichnen dabei beeindruckende Formen in die Luft. Die Bewegungen des gesamten Schwarms sind so komplex, wie ergeben sie sich aus dem Verhalten eines jeden einzelnen Vogels? Kurz gesagt: Wie funktioniert ein solches System? Ein Vogelschwarm und der Verkehr haben eines gemeinsam: In derart komplexen Systemen interagieren viele Einheiten miteinander und beeinflussen sich gegenseitig. Es ist jedoch schwierig, alle Wechselwirkungen in analytische Gleichungen einzubeziehen. Das Verhalten jeder einzelnen Einheit (z.B. Autos) kann dagegen leicht modelliert werden. Durch die Simulation einer Reihe von einzelnen Autos kann man also zum Beispiel zumindest vorhersagen, wie bestimmte Veränderungen (etwa die Intervallschaltung einer Ampel) den Verkehrsfluss beeinflussen könnten. 
        \n In einer Stadt ist es sehr schwierig, den Verkehr mit all seinen Wechselwirkungen als Ganzes zu beschreiben. Ebenso schwierig ist es, die sich ständig verändernde Form eines ganzen Vogelschwarms in eine Gleichung einzubeziehen. Es lassen sich jedoch grobe Beschreibungen der Regeln finden, denen ein einzelner Vogel folgen kann. In gleicher Weise haben Verkehrsmodellier*innen und -planer*innen ein gutes Verständnis für die spezifischen Regeln, denen ein einzelnes Verkehrsmittel innerhalb dieses Systems folgt. Aus diesen Regeln kann eine detaillierte digitale Nachbildung eines realen Verkehrssystems auf Basis verschiedener Annahmen gebildet werden.        
`,
        image: "./logos/birds.png",
      },
      {
        question:
          "Warum nutzen wir eine agentenbasierte Modellierung und keine klassischen Erhebungsmethoden?",
        answer: `Traditionelle Methoden zur Analyse von Verkehrsverhalten basieren auf statistischen Durchschnittswerten und mathematischen Gleichungen. Diese Ansätze betrachten vor allem Verkehrsfluss, Zyklen und Staubildung. Sie bieten jedoch nur begrenzte Einblicke in die Vielfalt des individuellen Verhaltens im komplexen System. Eine agentenbasierte Modellierung nutzt eine andere Herangehensweise und modelliert einzelne Akteure (Agenten). Jeder Agent repräsentiert ein autonom agierendes Element (wie in unserem Fall Personen mit ihren unterschiedlichen Verkehrsmitteln). Auf diese Weise ermöglicht ein ABM eine realistischere Simulation von Verkehrsverhalten, da individuelle Entscheidungen sowie soziale Interaktionen einbezogen werden und die Agenten grundsätzlich in der Lage sind, ihr Verhalten an verändernde Bedingungen anzupassen. Dies verbessert die Genauigkeit und Relevanz von Verkehrsmodellen, insbesondere in städtischen Gebieten mit komplexen Mobilitätsdynamiken.
`,
      },
      {
        question:
          "Auf welcher Technologie basiert das agentenbasierte Verkehrsmodell?",
        answer: `Die Modellierung basiert auf der MATSIM Technologie. MATSIM steht für "Multi-Agent Transport Simulation" und ist eine Open-Source-Simulationsumgebung, Es bietet ein Rahmenwerk (Framework) für die Implementierung von groß angelegten agentenbasierten Verkehrssimulationen. Das Framework besteht aus mehreren Modulen, die kombiniert oder einzeln verwendet werden können. Die Module können durch eigene Implementierungen ergänzt oder ersetzt werden. Weitere Informationen unter folgendem <a href="https://www.matsim.org/">Link</a>.
`,
      },
    ],
  },
  {
    description: `Die Agenten sind auf Basis von Daten des Katasteramts sowie der statistischen Ämter mit bestimmten Eigenschaften und sozio-ökonomischen Faktoren belegt. Zu den Attributen der Agenten zählen bspw. das Alter, Geschlecht, die Anzahl der im Haushalt lebenden Personen, ihr Kaufkraftindex, ob sie über ein Auto verfügen oder ob sie berufstätig bzw. in Ausbildung sind. Auf diese Weise kann die Demografie der Einwohner*innen Berlins abgebildet werden. Die Modellierung der Agenten erfolgt im Maßstab 1:4. Im ABM bewegen sich somit ca. 1.050.310 Agenten (Einwohnerzahl Berlins: 4.201.240).
`,
    dropdown: [
      {
        question: "Was ist ein Agent?",
        answer: `In einem ABM repräsentiert ein "Agent" eine eigenständige Einheit oder Entität (wie Personen mit einem Verkehrsmittel), die in der Lage ist, autonom zu handeln, ihre Umgebung wahrzunehmen und aufgrund ihrer internen Regeln Entscheidungen zu treffen. Agenten verfügen über bestimmte Attribute. Diese beschreiben ihren aktuellen Zustand. Vögel in einem Schwarm können zum Beispiel eine Position und eine Geschwindigkeit haben. Fahrzeuge verfügen zusätzlich über einen Motorisierungstyp (Diesel, Elektroantrieb etc.) oder eine maximale Anzahl an Passagieren.
        \n Ein entscheidender Teil eines ABM sind die Interaktionen zwischen Agenten. Dazu benötigen die Agenten ein bestimmtes Update-Schema. Darin sind eine Reihe von Regeln festgelegt, nach denen Agenten ihren Status aktualisieren, wenn sie mit anderen Agenten interagieren. In einer Verkehrssimulation folgt aus diesen Regeln etwa, dass ein Agent entschleunigt, wenn das Auto dem vorausfahrenden Agenten zu nahe kommt. In Zusammenhang mit der Interaktion ist die Nachbarschaft der Agenten ein wichtiges Konzept. Die Nachbarschaft definiert die Gruppe, mit der ein Agent interagiert. Bei einer Vogelschwarmsimulation kann die Nachbarschaft eines Vogels der Vogel sein, der ihm am nächsten ist.        
`,
      },
    ],
  },
  {
    description: `Neben den Agenten wird die Verkehrsinfrastruktur Berlins im Modell abgebildet. Darin gehen aktuelle Datensätze zum Verkehr in der Stadt ein, u.a. Daten der Berliner Verkehrsbetriebe (BVG) sowie anonymisierte Bewegungsdaten. Agenten bewegen sich in diesem Verkehrsnetz aufgrund verschiedener Aufgaben (im Sinne von Wegzielen wie bspw. dem Weg zur Arbeit), die sie erledigen müssen. Dabei müssen sie entscheiden, welches die entsprechend ihrer individuellen Eigenschaften attraktivste Route zum Erreichen ihres Ziels ist und welche(s) Verkehrsmittel sie nutzen müssen; kurz: welcher Mobilitätsmodus und welche Route für sie den individuell größtmöglichen Nutzen liefert.
`,
    dropdown: [
      {
        question: "Wie “entscheiden” Agenten?",
        answer: `Agenten folgen vorprogrammierten Zielen. Diese lassen sich aus den sozio-demographischen Profilen, Wohn- und Arbeitsorten sowie deren Wünschen und weiteren Parametern errechnen. Die Agenten "entscheiden" sich also nicht, sondern versuchen bestmöglich ihren Tagesablauf (z. B. die Reise vom Wohnort zur Arbeitsstelle und von dort zu einer Freizeitaktivität und wieder zurück) zu absolvieren. Die vorgegebenen Aktivitäten bestimmen jedoch nicht das Mobilitätsverhalten. 
        \n Die Agenten zeigen ein "emergentes" Verhalten, dass sich aus dem dynamischen Gleichgewicht des Systems ergibt. Das bedeutet: Im ABM werden den Agenten zunächst zufällige Verkehrsmodi zur Verfügung gestellt und ebenso zufällige Wege vorgeschlagen. Die Agenten konkurrieren dabei um die Kapazitäten je Modus (z.B. die verfügbaren Plätze im ÖPNV) und Wegesegment (etwa die Auslastung der Straßen). Wenn alle gleichzeitig mit Autos auf den gleichen Wegen fahren, kommt es zum Stau. Wartezeiten entstehen, wenn die maximale Buskapazität erreicht ist oder Busse ebenfalls im Stau stehen. Verspätungen werden über eine Kostenfunktion "bestraft". Am Ende eines Tagesablaufes werden die Gesamtkosten aller Agenten für die zurückgelegten Wege berechnet. 
        \n An dieser Stelle setzt der sog. evolutionäre Algorithmus ein. Die Funktionsweise dieser Algorithmen ist von der Evolution natürlicher Lebewesen inspiriert. Verschiedene Lösungsmöglichkeiten für eine Aufgabe bzw. Problem (wie hier z.B.: einen Modus und eine Route finden, die den individuellen Nutzen der Agenten maximiert) werden für alle Agenten in großer Zahl künstlich erzeugt und bewertet. In der nächsten Iteration wird eine weitere zufällige "Mutation" eingeführt. Die Zuteilung von Agenten zu Modi und Wegen wird neu gemischt und jedem Agenten werden die "besten" Eigenschaften des Vortages ("Elterngeneration") mitgegeben. Die gesamte Simulation wird dann erneut berechnet, ausgewertet, mutiert und vererbt. Diese Berechnung wird vielfach wiederholt. Auf diese Weise können gut bewertete Lösungsansätze stetig verbessert und in diesem Fall die Wahl des Verkehrsmittels und die Routenwahl der Agenten Schritt für Schritt optimiert werden. Da die Lösungen immer im Gesamtkontext des ABM bewertet und die Entscheidungen in Abhängigkeit zu dem Verhalten der anderen Agenten optimiert werden, spricht man hier von ko-evolutionärer Optimierung. 
        \n Durch diesen Optimierungsprozess entsteht ein so genanntes dynamisches Equilibrium. Die Veränderungen im Ausgangsmix von Modus und Weg je Agent erreichen dann keine wesentlichen Verbesserungen der Gesamtkosten im System mehr. In jedem Planspiel kommen jedoch weitere Kostenfaktoren hinzu, die das Gesamtverhalten der Agenten beeinflussen. So "lernen" Agenten über tausende Iterationen, sich dynamisch an die jeweiligen Rahmenbedingungen und Kostenfaktoren in den Szenarien anzupassen.
        \n Menschen entscheiden sich zwar bewusst für die Wahl der Modi und der Wege, tun dies jedoch basierend auf den Erfahrungen und Kosten vergleichbarer Entscheidungen. Zugleich betreffen die individuell getroffenen Entscheidungen wechselseitig immer auch die der Gesamtheit (und umgekehrt). Menschen verändern ihr Mobilitätsverhalten ebenfalls unter veränderten Kosten- und Nutzenwahrnehmungen. ABM ist nach aktuellem Stand die bisher beste Art der Simulation von Mobilität, da das simulierte Verhalten der Agenten dabei dem Verhalten tatsächlicher menschlicher Mobilität entspricht.                
`,
      },
      {
        question:
          "Nach welchen Kriterien entscheiden Agenten, um ihren Nutzen zu maximieren?",
        answer: `Agenten stehen vor einem Optimierungsproblem: Sie möchten denjenigen Mobilitätsmodus und diejenige Route finden, die für sie den individuell größtmöglichen Nutzen liefern. Dieses Problem kann mathematisch gelöst werden. Die Nutzenoptimierung basiert grundsätzlich auf dem Vickrey-Modell (William S. Vickrey, 1963) und wird für die aktivitätenbasierte Nachfrage durch Charypar und Nagel (2005) mit zusätzlichen Komponenten ergänzt.  
        \n Der grundsätzliche Gedanke ist, dass die Kosten für Aktivitäten bei veränderten Rahmenbedingungen variieren. Das ist insbesondere im Wettbewerb der Fall, also wenn eine erhöhte Nachfrage auf ein gleichbleibendes Angebot trifft. Ein Beispiel: Die Preise für Hotelzimmer in Urlaubsregionen sind in den Ferien deutlich teurer als in der Schulzeit. Die Kosten für eine Aktivität lassen sich jedoch nicht immer nur in Geld bemessen. Übertragen auf den Verkehr in einer Stadt: Nachts ist auf vielen Strecken in der Regel weitaus weniger Verkehr als im Berufsverkehr am späten Nachmittag. Als Konsequenz ist die Reisedauer für die gleiche Strecke in der Nacht deutlich kürzer als im Berufsverkehr, wo im gleichen Verkehrsraum mehr Verkehrsteilnehmer*innen zum Ziel kommen möchten. Ein Agent kann beim Testen verschiedener Lösungsmöglichkeiten in Abhängigkeit zum Verhalten anderer Agenten (Berufsverkehr = viele Agenten in der Nachbarschaft) vergleichen, wie sich die Reisedauer bei der Wahl verschiedener Mobilitätsmodi verändert. Möglicherweise bietet es für Agenten zu bestimmten Tageszeiten einen größeren Nutzen, auf den ÖPNV auszuweichen, weil das Ziel schneller erreicht werden kann. 
        \n In die Abwägung der Entscheidung fließen verschiedene monetäre und sonstige (nicht-monetäre und nicht-zeitliche) Faktoren ein. Agenten wägen diese Faktoren vor ihren individuellen sozio-ökonomischen Eigenschaften ab. Wie vielschichtig diese Abwägung sein kann, veranschaulichen die folgenden Beispiele: 
        \n • Für Agenten, die mit einem Alter unter 18 Jahren attribuiert sind, ist die Entscheidung, alleine mit dem Auto zu fahren, von vornherein ausgeschlossen. Diese Agenten können lediglich bei anderen Agenten mitfahren und müssen ansonsten auf andere Modi ausweichen. 
        \n • Die gleiche Strecke kann für Agenten mit Autobesitz verschieden bewertet werden. Ein Agent, der alleine im Haushalt lebt, können hohe Kosten für das Zurücklegen einer Strecke das Umsteigen auf andere Modi bedeuten. Für Agenten mit Autobesitz, die mehreren anderen Agenten im Haushalt wohnen, bietet das Auto womöglich aber trotz hoher Kosten den größten Nutzen, da sich diese Kosten auf weitere mitfahrende Agenten verteilen. 
        \n • Für Agenten, die sich aufgrund von Freizeitaktivitäten fortbewegen, ist die Reisedauer womöglich weniger entscheidend als für Agenten, die die gleiche Strecke zurücklegen, um zum Arbeitsort zu gelangen. 
        \n • Für einen Agenten mit einer höheren Kaufkraft (abgebildet über den Kaufkraftindex) sind deutlich höhere Parkgebühren eher weniger entscheidungsrelevant bei der Wahl des Verkehrsmittels als für einen Agenten mit geringer Kaufkraft. 
        \n • Ein Agent mit geringer Kaufkraft kann im Entscheidungsprozess feststellen, dass der ÖPNV für ihn insg. den größeren Nutzen hat als der PKW und der Kauf des teuren Parktickets, auch wenn der Weg unter Umständen länger dauert. Bei gleicher Strecke kann der PKW jedoch für einen Agenten mit hoher Kaufkraft den größtmöglichen Nutzen bieten, da dieser Agent die Parkkosten eher bezahlen kann und zudem schneller ans Ziel gelangt. 
        
        Die Optimierung des individuellen Nutzens der Agenten im ko-evolutionären Entscheidungsprozess erfolgt mithilfe wissenschaftlich fundierter Methoden. Diese sind in den folgenden Quellen beschrieben. 
        
        Verweise auf die wissenschaftlichen Quellen:
        \n <a href="https://www.research-collection.ethz.ch/handle/20.500.11850/74456">Andreas Horni (2013)<a> Destination choice modeling of discretionary activities in transport microsimulation, PhD Dissertation, IVT, ETH Zürich, Zürich.
        \n <a href="https://link.springer.com/article/10.1023/A:1012905110686">Ram M. Pendyala  et al. (2002)</a> On the formulation of time-space prisms to model constraints on personal activity-travel engagement, Transportation, Volume 29, Issue 1, pp 73-94
        \n <a href="https://cooperative-individualism.org/vickrey-william_pricing-in-urban-and-suburban-transport-1963-may.pdf">William S. Vickrey (1963)</a>  Pricing in Urban and Suburban Transport, The American Economic Review, Vol. 53, No. 2
        \n <a href="https://link.springer.com/article/10.1007/s11116-004-8287-y">David Charypar und Kai Nagel (2005)</a> Generating complete all-day activity plans with genetic algorithms, Transportation, Volume 32, Issue 4, pp 369-397
        \n <a href="https://www.ubiquitypress.com/site/books/e/10.5334/baw/">Horni, A., Nagel, K. and Axhausen, K.W. (eds.) 2016</a> The Multi-Agent Transport Simulation MATSim. London: Ubiquity Press. DOI: http://dx.doi.org/10.5334/baw. License: CC-BY 4.0
        \n <a href="https://papers.cumincad.org/data/works/att/ecaade2022_133.pdf">Grisiute, A., Silvennoinen, H., Li, S., Chadzynski, A., von Richthofen, Aurel, & Herthogs, P. (2022)</a>. Unlocking Urban Simulation Data with a Semantic City Planning System—Ontologically representing and integrating MATSim output data in a Knowledge Graph. ECAADe 40, 2, 257–266.
                        
`,
      },
    ],
  },
  {
    description: `Zusammengefasst: Das ABM repräsentiert die gesamte verkehrliche Infrastruktur und die Bevölkerung Berlins in einer möglichst hohen Auflösung. Aus dieser Modellierung können detaillierte Informationen über Mobilität und Aktivitäten abgeleitet werden. Dazu zählen etwa Änderungen im Mobilitätsverhalten der Agenten oder die sich verändernden Auslastungen der Infrastruktur. Das ABM kann sowohl das aktuelle und auch zukünftige Mobilitätsverhalten der Bürger*innen simulieren. Für die in diesem Dashboard dargestellten Berechnungen wurde es benutzt, um die Auswirkungen der o.g. Mobilitätsmaßnahmen auf den Verkehr und die dadurch entstehenden Emissionen (Luftschadstoffe)  zu simulieren.
`,
    image: "./logos/city.png",
    width: "160px",
  },
  {
    description: `Wie wird das Verhalten der Agenten mit der Realität abgeglichen?
    Das agentenbasierte Verkehrsmodell basiert auf qualitativ hochwertigen Eingangsdaten und wird regelmäßig aktualisiert. So enthält es bspw. die aktuellen Daten der öffentlichen Verkehrsbetriebe in Berlin (BVG), aktuelle Straßennetze und soziodemographische Statistiken.    
`,
    dropdown: [
      {
        question:
          "Welche Datenquellen stecken im Detail im agentenbasierten Modell?",
        answer: `Folgende Datenquellen fließen in das ABM ein: 
        \n •	Bevölkerung: Microm
        \n •	Straßennetz: OSM
        \n •	Fahrplan: Delfi
        \n •	Aktivitätenbasierte Nachfrage: MiD und Mobilfunkdaten
        \n •	Raumtypen: RegioStaR 17
        \n •	Zählungen: Zählungen der Länder und der BASt (Bundesanstalt für Straßenwesen)
`,
      },
    ],
  },
  {
    description: `Ob bzw. inwieweit die Szenarien mit dem Zeithorizont 2030 tatsächlich wie berechnet eintreten würden, ist zum gegenwärtigen Zeitpunkt nicht mit Bestimmtheit festzustellen. Der Grund dafür ist, dass niemand vorhersagen kann, wie sich Berlin in den nächsten Stunden, Tagen, Wochen, Monaten und Jahren entwickeln wird. Es existieren jedoch viele Daten, die die Verkehrssituation im Berliner Stadtgebiet sowohl in der Gegenwart als auch in der Vergangenheit abbilden. Diese Daten wurden dazu genutzt, das im Dashboard dargestellte Gegenwarts-Szenario (Szenario 1) mit den Verkehrsverhältnissen in Berlin im Jahr 2023 abzugleichen. 
    \n Das Ergebnis dieses Kalibrierungs- und Validierungsprozesses ist ein sehr realitätsgetreues Abbild des gegenwärtig tatsächlich vorhandenen Verkehrs in Berlin. In diesem validierten Szenario sind auch die Bewertungsschemata abgebildet, nach denen Agenten sich unter den gegebenen Bedingungen für eine bestimmte Mobilitätsform und Route entscheiden, um den für sie größtmöglichen Nutzen zu erzielen. Ein Abgleich mit der Realität im Jahr 2030 ist zum gegenwärtigen Zeitpunkt dagegen unmöglich. Mit dem kalibrierten und validierten Gegenwarts-Szenario, das die Grundlage für die Modellierungsberechnungen darstellt, können die Ergebnisse aller Szenarien im Jahr 2030 jedoch als plausibel angenommen werden.        
`,
    dropdown: [
      {
        question: "Von der validierten Gegenwart zum fiktiven Planspiel 2030",
        answer: `Ausgehend vom Gegenwarts-Szenario (Szenario 1) werden das Szenario Plan 2030 (Szenario 2), alle Planspiele (Szenarien 3-5) sowie deren Kombination (Szenario 6) gebildet. Dies geschieht durch die gezielte Veränderung verschiedener Parameter. Eine solche Veränderung ist bspw. die Erhöhung der Parkkosten (Szenario 3) oder die Definition bestimmter Gebiete, die nicht mehr mit bestimmten Fahrzeugarten befahren werden dürfen (Szenario 4). Diese veränderten Bedingungen werden in der Modellierung mit abstrahierten monetären und nicht-monetären (insb. zeitlichen) Kosten belegt. Ein gewählter Modus und eine bestimmte Route, die für einen Agenten mit individuellen sozio-ökonomischen Eigenschaften im validierten Gegenwarts-Szenario den größtmöglichen Nutzen bot, kann unter veränderten Parametern so unattraktiv werden, dass sich der Agent anders entscheidet. Die Entscheidung, wie sich die Agenten in den verschiedenen Szenarien bewegen, wird entsprechend der Parameter der jeweiligen Planspiele vor den für ihren Weg vom Start zum Ziel entstehenden Kosten bewertet (siehe oben). Die Bewertung, welche Kosten für welche Agenten akzeptabel sind, hängt maßgeblich von ihren individuellen Eigenschaften ab.
`,
      },
    ],
  },
  {
    title: "Berechnung der Emissionswerte nach HBEFA",
    description: `Die Berechnung der entstehenden verkehrsbedingten Emissionen baut auf der Verkehrsmodellierung in den einzelnen Szenarien auf. Die in der Verkehrsmodellierung errechneten Daten enthalten detaillierte Informationen zu Infrastruktur, Verkehrsaufkommen, Fahrzeugdetails und Verkehrsbedingungen in den einzelnen Szenarien. Auf Basis dieser Daten können entsprechend dem <a href="https://www.hbefa.net/de/startseite">Handbuch für Emissionsfaktoren des Straßenverkehrs</a> die verkehrsbedingten Emissionen errechnet werden (kurz: HBEFA). 
`,
    dropdown: [
      {
        question: "Was genau ist das Handbuch für Emissionsfaktoren (HBEFA)?",
        answer: `Das Handbuch für Emissionsfaktoren (kurz HBEFA) ist ein technisches Referenzwerk, das Informationen über die Emissionen verschiedener Schadstoffe aus unterschiedlichen Quellen bereitstellt. Es ist speziell auf den Verkehrssektor ausgerichtet und bietet genaue Daten zu den Emissionsfaktoren, die bei der Berechnung von Umweltauswirkungen (insbesondere Luftverschmutzung) im Zusammenhang mit Verkehrsmitteln verwendet werden.
`,
      },
    ],
  },
  {
    description: `Der von Arup entwickelte Algorithmus zur Berechnung der Emissionen verbindet die verschiedenen emissionsrelevanten Faktoren im Verkehr je Stunde und Straßenabschnitt. Das beinhaltet bspw. 
    \n das Zuweisen von HBEFA-Straßentypen (von der Autobahn bis zur Nebenstraße mit Kopfsteinpflaster), 
    \n die Bestimmung des sog. Level of Service (fließender Verkehr, Stop&Go im Berufsverkehr etc.), 
    \n die Anwendung eines Flottenmixes für verschiedene Fahrzeug- und Kraftstofftypen (mit jeweils unterschiedlicher Schadstofflast) und 
    \n die Berechnung von heißen und kalten Emissionen für Privatfahrzeuge und Busse. 
    Generell folgt die Emissionsberechnung zwei verschiedenen Ansätzen, die sich vor allem in der Menge der verarbeiteten Daten und in der Komplexität der Datenverarbeitung unterscheiden.    
`,
    dropdown: [
      {
        question:
          "Inwiefern unterscheiden sich die Ansätze zur Berechnung der Emissionen?",
        answer: `Die erste Methode schließt das komplette Straßennetzwerk und die Aktivitäten aller Agenten mit ein. Dadurch entstehen lange Datenverarbeitungszeiten und große Datenmengen. Diese Methode ist für die genaue Berechnung von Emissionen und den räumlichen Vergleich in Form von Karten zwischen den verschiedenen Szenarien gedacht. Die zweite Methode beruht auf geringeren Datenmengen zu den Agenten und deren Aktivitäten sowie einer gröberen Datenaggregation. Dies ermöglicht eine genaue und für den spezifischen Bearbeitungsfall ausreichende Schätzung von Emissionen für Fokusgruppen und die Bewertung der relativen Veränderungen zwischen verschiedenen Szenarien. Die Verarbeitung sehr großer Datenmengen und komplexer Algorithmen kann auf diese Weise umgangen werden.
`,
      },
    ],
  },
  {
    title: "Erstellung des Benutzeroberfläche mit REACT JS",
    description: `In diesem Dashboard werden die oben beschriebenen Analyseergebnisse visualisiert und der Berliner Bevölkerung, aber auch allen Interessierten darüber hinaus frei zugänglich zur Verfügung gestellt. Im Rahmen der Szenarien und Fokusgruppen stellen Karten räumliche Informationen zu allen Transportmodi und Emissionstypen auf Basis eines 50m x 50m Rasters zur Verfügung. Diagramme liefern quantitative Informationen zu einzelnen Themenbereichen und ermöglichen den Vergleich. Die Benutzeroberfläche soll eine intuitive Navigation durch alle Inhalte ermöglichen.
    \n Die Grundlage der Applikation bildet das Framework REACT JS, die vom Alexander von Humboldt Institut für Internet und Gesellschaft gGmbH auf einem Webserver betrieben wird. Die Applikation besteht aus einem Ordnerverzeichnis, das über eine Vielzahl verschiedener TypeScript-, HTML- und CSS-Dateien alle Funktionalitäten des Dashboards aus dem Backend steuert. Auf dem Webserver liegen zudem alle Quelldaten, die für die Visualisierung der Diagramme und Karten benötigt werden. Für die Darstellung der Diagramme wurde die Open-Source-JavaScript-Bibliothek “CHART JS” genutzt. Die interaktiven Karten wurden mit der Bibliothek “LEAFLET JS” entwickelt. Zusätzlich wurde die quelloffene Leaflet-Erweiterung “georaster-layer-for-leaflet” genutzt, um GEOTIFF-Bilddateien einzubinden.    
`,
    image: "./logos/react.png",
    dropdown: [
      {
        question: "Was ist REACT JS?",
        answer: `   REACT JS ist eine Open-Source-JavaScript-Bibliothek, die zur Erstellung von Benutzeroberflächen (UIs) für Webseiten verwendet wird. Sie ist eine der beliebtesten UI-Bibliotheken und eignet sich zum Erstellen der Interfaces komplexer Webservices, die von vielen User*innen genutzt werden können. REACT JS basiert auf sog. Komponenten. Dies sind kleine, wiederverwendbare Bausteine, die eine bestimmte Aufgabe ausführen (bspw. eine Schaltfläche, die eine Liste oder ein Formular steuert).
`,
      },
      {
        question:
          "Was sind HTML, TypeScript und CSS und wie wirken diese in einer Applikation zusammen?",
        answer: `In einer REACT-App verwenden Entwickler*innen HTML, CSS und TypeScript, um eine interaktive Benutzeroberfläche zu erstellen. Grundsätzlich handelt es sich um verschiedene Sprachen, die innerhalb einer Applikation jeweils bestimmten Zwecken dienen.
        \n Hypertext Markup Language (kurz HTML) ist eine textbasierte Auszeichnungssprache, die verwendet wird, um die Struktur einer Webseite zu definieren. Sie beschreibt, welche Elemente auf einer Seite angezeigt werden sollen, wie diese miteinander verbunden sind und welche Inhalte sie enthalten. HTML wurde in diesem Projekt u.a. dafür verwendet, Schaltflächen zu erstellen und das Dashboard zu strukturieren.
        \n TypeScript (kurz TS) ist eine objektorientierte Programmiersprache, die auf JavaScript basiert. Sie wird vor allem genutzt, um die Funktionen der Webseite zu entwickeln und dynamische Inhalte hinzuzufügen. In diesem Dashboard wurde TS genutzt, um die Zoomfunktionen der Karten und das dynamische Ein- und Ausblenden der Menüleisten bereitzustellen.
        \n Cascading Style Sheets (kurz CSS) ist eine Stylesheet-Sprache, die verwendet wird, um die Darstellung einer Webseite zu definieren. Sie legt fest, wie die Elemente auf der Seite aussehen, wie groß sie sind, welche Farben sie haben und wie sie positioniert sind. In diesem Dashboard wurde CSS u.a. dafür verwendet, um den Schrifttyp „Berlin Font Family“ und die Farbskalen festzulegen.
`,
      },
      {
        question: "Wie funktioniert ein Webserver?",
        answer: `Ein Webserver ist ein Computerprogramm, das Dateien über das Internet bereitstellt und REACT-Anwendungen ausführt. Wenn ein*e Benutzer*in z.B. dieses Dashboard (eine REACT-App) besucht, sendet der Browser eine Anfrage an den Webserver. Der Webserver sendet dann die angeforderte Datei an den Browser zurück, der anschließend den JavaScript-Code ausführt und die Benutzeroberfläche des Dashboards erstellt.  
`,
      },
    ],
  },
];
