import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { onEachFeature, setUpMap } from "./util";
import { fetchGeoJson, fetchGeoRasterLayer } from "../services/layerFetcher";
import { tabs } from "../scenarioComponent/tabs";
import { ScenarioControl } from "../scenarioComponent/ScenarioControl";
import { ScenarioDescription } from "../scenarioComponent/scenarioDescription/ScenarioDescription";
import StickyTabs from "../helperComponents/stickyTabs/StickyTabs";
import { emission, verkehr } from "./modes";
import { scenarioContent } from "../scenarioComponent/scenarioDescription/ScenarioContent";
import Legend from "./mapLegend";
import { emissionLegend, verkehrLegend } from "./legend";
import { MainChartsComponent } from "./mainChartsComponent";

const MainMapComponent = () => {
  const mapRef = useRef(null);
  const latitude = 51.505;
  const longitude = -0.09;

  const [type, setType] = useState("all");
  const [feature, setFeature] = useState<any>(null);
  const [scenario, setScenario] = useState("base_min_2023");
  const [mode, setMode] = useState("verkehr");
  const [prevZoomLevel, setPrevZoomLevel] = useState(13);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    initiateMap();
  }, [mode, type, scenario]);

  const initiateMap = async () => {
    let georasters = [];

    const url_to_geojson_file = `/lor_zone_diagrams/${scenario}.geojson`;
    const geojsonLayer = await fetchGeoJson(url_to_geojson_file);

    if (type === "all") {
      const promises =
        mode === "verkehr"
          ? verkehr.map(async (verkehrType: string) => {
              return await fetchGeoRasterLayer(
                `/tif_scenarios_${mode}_maps/${scenario}_${verkehrType}.tif`
              );
            })
          : emission.map(async (emissionType: string) => {
              return await fetchGeoRasterLayer(
                `/tif_scenarios_${mode}_maps/${scenario}_${emissionType}.tif`
              );
            });
      georasters = await Promise.all(promises);
    } else {
      const url_to_geotiff_file = `/tif_scenarios_${mode}_maps/${scenario}_${type}.tif`;

      georasters.push(await fetchGeoRasterLayer(url_to_geotiff_file));
    }
    const map = mapRef.current as L.Map | null;

    map?.on("zoom", (e: any) => {
      handleZoomChange(geojsonLayer, e);
    });

    let mapGeojsonLayer = handleLayer(geojsonLayer, map);
    setUpMap(map, mode, mapGeojsonLayer, georasters, type);
    setTimeout(() => {
      setLoading(false); // Set loading to false after 3 seconds
    }, 500);
  };

  const handleModeChange = (newValue: any) => {
    setType("all");
    console.log(newValue);
    setMode(newValue);
  };

  const handleTypeChange = (event: React.SyntheticEvent, newValue: any) => {
    setType(newValue);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
    setMode(mode);
    setScenario(newValue);
  };

  const renderScenarioDescription = () => {
    let current = tabs.find((selectedTab) => selectedTab.value === scenario);
    return <ScenarioDescription tab={current}></ScenarioDescription>;
  };

  const renderScenarioTitle = () => {
    let current = tabs.find((selectedTab) => selectedTab.value === scenario);
    return (
      <Typography
        variant="h2"
        component="h2"
        color={"primary.main"}
        style={{
          textAlign: "center",
          margin: "0 0 10px 0",
        }}
      >
        Szenario {current?.id}:{" "}
        {scenarioContent[current ? current?.id - 1 : 0]?.title}
      </Typography>
    );
  };

  const handleLayer = (geojsonLayer: any, map: any) => {
    let features = geojsonLayer.features;
    setFeature(features[features.length - 1]);
    let mapGeojsonLayer = L.geoJSON(geojsonLayer, {
      style: function (feature) {
        return {
          weight: 0.0,
          color: "",
          fillOpacity: 0,
        };
      },
      onEachFeature: (feature, layer) =>
        onEachFeature(feature, layer, setFeature, map),
    });
    return mapGeojsonLayer;
  };

  const handleZoomChange = (geojsonLayer: any, event: any) => {
    const currentZoomLevel = event.target.getZoom();
    if (currentZoomLevel > prevZoomLevel) {
    } else if (currentZoomLevel < prevZoomLevel) {
      let features = geojsonLayer.features;

      setFeature(features[features.length - 1]);
    }
    setPrevZoomLevel(currentZoomLevel);
  };
  return (
    <Container
      maxWidth="lg"
      id="section1"
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "60px",
        alignItems: "flex-start",
      }}
    >
      <StickyTabs
        tabs={tabs}
        tab={scenario}
        handleTabChange={handleTabChange}
        id={"section1"}
      ></StickyTabs>

      {renderScenarioTitle()}
      <Grid
        container
        style={{ display: "flex", width: "100%", alignItems: "stretch" }}
      >
        <Grid item xs={12} md={9}>
          <MapContainer
            center={[latitude, longitude]}
            zoom={11}
            minZoom={9}
            maxZoom={15}
            ref={mapRef}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "620px",
              zIndex: 500,
            }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              opacity={0.2}
              url="https://api.mapbox.com/styles/v1/mapbox/light-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiamFuY3k5MSIsImEiOiJjbG5sanNiamcxMGR6MmtwMjgzNTQ3d3hrIn0.58LRx0M6ObCa1oaq7mIMLw"
            />

            <ScenarioControl
              position="topright"
              type={type}
              verkehrTitle={"Anzahl der Fahrzeuge je Verkehrsträger"}
              handleModeChange={handleModeChange}
              handleTypeChange={handleTypeChange}
            />
            <Legend
              legendValues={mode === "verkehr" ? verkehrLegend : emissionLegend}
            />
            {loading ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CircularProgress />
              </Box>
            ) : null}
          </MapContainer>
          {renderScenarioDescription()}
        </Grid>
        <Grid
          item
          md={3}
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: 1,
            height: "620px",
            width: "100%",
          }}
        >
          {feature && !loading ? (
            <MainChartsComponent feature={feature}></MainChartsComponent>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default MainMapComponent;
