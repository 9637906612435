import { Grid, Container } from "@mui/material";

const FooterComponent = () => {
  return (
    <Container
      maxWidth="sm"
      style={{
        marginTop: "100px",
        marginBottom: "50px",
        display: "flex",
        padding: "0 50px",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid
        container
        spacing={2}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item sm={3} style={{ display: "flex", justifyContent: "center" }}>
          <a href="https://www.hiig.de/">
            {" "}
            <img
              src="./logos/Humboldt.png"
              alt="HIIG Logo"
              style={{
                maxWidth: "100%",
                maxHeight: "100px",
                objectFit: "contain",
              }}
            />{" "}
          </a>
        </Grid>
        <Grid item sm={3} style={{ display: "flex", justifyContent: "center" }}>
          <a href="https://kompetenz-wasser.de/de">
            {" "}
            <img
              src="./logos/kwb.png"
              alt="KWB Logo"
              style={{
                maxWidth: "100%",
                maxHeight: "100px",

                objectFit: "contain",
              }}
            />{" "}
          </a>
        </Grid>
        <Grid item sm={3} style={{ display: "flex", justifyContent: "center" }}>
          <a href="https://www.arup.com/">
            {" "}
            <img
              src="./logos/Arup.png"
              alt="Arup Logo"
              style={{
                maxWidth: "100%",
                maxHeight: "100px",
                objectFit: "contain",
              }}
            />{" "}
          </a>
        </Grid>
        <Grid item sm={3} style={{ display: "flex", justifyContent: "center" }}>
          <a href="https://senozon.com/">
            {" "}
            <img
              src="./logos/senozon.png"
              alt="Senozon Logo"
              style={{
                maxWidth: "100%",
                maxHeight: "100px",
                objectFit: "contain",
              }}
            />{" "}
          </a>
        </Grid>
        <Grid item sm={3} style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <a href="https://www.berlin.de/rbmskzl/">
            {" "}
            <img
              src="./logos/berlin.png"
              alt="Berlin Logo"
              style={{
                maxWidth: "100%",
                maxHeight: "100px",
                objectFit: "contain",
              }}
            />{" "}
          </a>
        </Grid>
        <Grid item sm={3} style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <a href="https://www.bmwsb.bund.de/Webs/BMWSB/DE/startseite/startseite-node.html">
            {" "}
            <img
              src="./logos/bundes.png"
              alt="Bundes Logo"
              style={{
                maxWidth: "100%",
                maxHeight: "100px",
                objectFit: "contain",
              }}
            />{" "}
          </a>
        </Grid>
        <Grid item sm={3} style={{ display: "flex", justifyContent: "center" }}>
          <a href="https://www.kfw.de/kfw.de.html">
            {" "}
            <img
              src="./logos/kfw.png"
              alt="KFW Logo"
              style={{
                maxWidth: "100%",
                maxHeight: "100px",
                objectFit: "contain",
              }}
            />{" "}
          </a>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FooterComponent;
